/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import * as H from 'history';
import { matchPath } from 'react-router-dom';
import { ANALYTICS_UNKNOWN_ROUTE } from '@services/analytics/analytics.constants';

interface PageInfo {
  pathname: string;
  routeName: string;
  search: string;
}

interface NavigationServiceOptions {
  locationChangeDebounceDelay?: number;
  ignoredRoutes?: (string | RegExp)[];
}

/**
 * NavigationService is responsible for tracking and managing page navigation events.
 * It listens to location changes, updates the current and previous page information,
 * and triggers a callback when a page change is detected.
 */
export class NavigationService {
  /**
   * Default options for the NavigationService.
   *
   * @property {number} locationChangeDebounceDelay - The delay in milliseconds to debounce location changes.
   * @property {string[]} ignoredRoutes - An array of route patterns to ignore for analytics tracking.
   */
  private static readonly defaultOptions: NavigationServiceOptions = {
    locationChangeDebounceDelay: 50,
    ignoredRoutes: [],
  };

  private static readonly initialPageInfo: PageInfo = {
    pathname: '',
    routeName: '',
    search: '',
  };

  private readonly routes: { name: string; path: string }[];

  private readonly options: NavigationServiceOptions;

  public currentPage: PageInfo = NavigationService.initialPageInfo;

  public previousPage: PageInfo | null = null;

  /**
   * Constructs an instance of the AnalyticsNavigationService.
   *
   * @param routes - A record of route names and their corresponding paths.
   * @param options - Optional configuration for the navigation service.
   *                   If not provided, default options will be used.
   */
  constructor(routes: Record<string, string>, options: NavigationServiceOptions = {}) {
    this.routes = Object.entries(routes).map(([name, path]) => ({
      name,
      path,
    }));
    this.options = { ...NavigationService.defaultOptions, ...options };
  }

  /**
   * Sets up a listener for location changes and immediately triggers the callback for the current location.
   */
  public listen(history: H.History, callback: () => void): H.UnregisterCallback {
    this.onChangeLocation(history.location, callback);

    return history.listen((location) => this.onChangeLocation(location, callback));
  }

  /**
   * Determines if the current page has changed compared to the previous page.
   *
   * @returns {boolean} `true` if the current page's pathname or search parameters
   *                    differ from the previous page's; otherwise, `false`.
   */
  public isPageChanged(): boolean {
    return (
      this.currentPage.pathname !== this.previousPage?.pathname ||
      this.currentPage.search !== this.previousPage?.search
    );
  }

  private onChangeLocation(location: H.Location, callback: () => void): void {
    if (this.isIgnoredRoute(location.pathname)) {
      return;
    }

    const routeName = this.getRouteName(location, ANALYTICS_UNKNOWN_ROUTE);
    const { pathname, search } = location;

    this.previousPage = this.currentPage;
    this.currentPage = {
      pathname,
      search,
      routeName,
    };

    callback();
  }

  private isIgnoredRoute(pathname: string): boolean {
    return this.options.ignoredRoutes?.some((route) => pathname.match(route)) ?? false;
  }

  private getRouteName(location: H.Location | null, otherwise: string): string {
    if (!location) {
      return otherwise;
    }

    const candidates = this.routes
      .map((route) => ({
        route,
        match: matchPath(location.pathname, { path: route.path }),
        strictMatch: matchPath(location.pathname, { path: route.path, strict: true }),
      }))
      .filter(({ match, strictMatch }) => match || strictMatch);

    if (candidates.length === 0) {
      return otherwise;
    }

    candidates.sort(NavigationService.compareRoutes);

    return candidates[0].route.name;
  }

  /**
   * Sort by strict match first, then by match, then by path length.
   *
   * This ensures that the most specific route is chosen:
   *   1. Strict matches are prioritized to ensure exact path matches.
   *   2. Non-strict matches are considered next to allow for flexible matching.
   *   3. Finally, shorter paths are prioritized to prefer more specific routes over generic ones.
   */
  private static compareRoutes(
    a: { strictMatch: any; match: any; route: { name: string; path: string } },
    b: { strictMatch: any; match: any; route: { name: string; path: string } },
  ): number {
    if (a.strictMatch && !b.strictMatch) {
      return -1;
    }
    if (!a.strictMatch && b.strictMatch) {
      return 1;
    }
    if (a.match && !b.match) {
      return -1;
    }
    if (!a.match && b.match) {
      return 1;
    }
    return a.route.path.length - b.route.path.length;
  }
}
