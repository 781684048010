/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createContext, useContext, useMemo, useState, useCallback, ReactNode } from 'react';
import { IConfigContext } from '@components/context/config/config.types';
import { APP_TYPE } from '@lib/common.constants';
import { setGlobalState } from '@root/src/store/global.actions';
import { GlobalActions } from '@app/store/use.global.store';

export const defaultConfigs = {
  applicationType: APP_TYPE.standalone,
  isExternal: false, // Computed value, added only for autocomplete functions,
};

export const ConfigContext = createContext<IConfigContext>(defaultConfigs);

export const useConfig = () => useContext(ConfigContext);

interface ContextProviderProps {
  children: ReactNode;
  value: IConfigContext;
}

export const ConfigProvider = ({ children, value = defaultConfigs }: ContextProviderProps) => {
  const [redirectNotificationDC, setRedirectNotificationDC] = useState(true);
  const computedValue = useMemo(
    () => {
      setGlobalState({ applicationType: value.applicationType }, GlobalActions.setApplicationType);
      return ({
        ...value,
        isExternal: value.applicationType === APP_TYPE.widget,
      });
    },
    [value],
  );

  const hideRedirectNotificationDC = useCallback(() => {
    setRedirectNotificationDC(false);
  }, []);

  const configValue = useMemo(
    () => ({
      ...computedValue,
      redirectNotificationDC,
      hideRedirectNotificationDC,
    }),
    [computedValue, redirectNotificationDC, hideRedirectNotificationDC],
  );

  return <ConfigContext.Provider value={configValue}>{children}</ConfigContext.Provider>;
};
