/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { LOCAL_STORAGE_KEYS, CONDITION_STRINGS } from '@lib/common.constants';
import * as Constants from '@lib/common.constants';
import { findDimension } from '@root/src/store/catalog.actions';
import { JSService } from '@services/js.service';
import { ScaleService } from '@services/scale.service';
import { ScoreColorService } from '@services/score-color.service';
import {
  CATALOG_STATE_TYPES,
  Dimension,
  DimensionList,
  IndicatorChoice,
  IndicatorInfo,
  LevelInfo,
  Statement,
  StatementList,
} from '@root/src/types/dimension.types';
import { User } from '@app/types/user.types';
import { BASE_CATALOG_VIEW_TYPES } from '@lib/event.constants';
import { AssessmentStatement, IScoreDetailList } from '@app/types/catalog.types';

interface CatalogServiceInterface {
  modifyCatalogState: (item: Dimension, catalogState: CATALOG_STATE_TYPES) => Dimension;
  indicators: (
    score: number | string,
    scaleType: Constants.ANSWER_TYPES,
  ) => { [key: string]: { name: string} };
  isArchived: (state: CATALOG_STATE_TYPES) => boolean;
  isPublic: (dimension: Dimension) => boolean;
  isSystem: (dimension: Dimension) => boolean;
  isDimensionType: (type: CATALOG_STATE_TYPES) => boolean;
  isInvalidDimension: (state: Constants.DIMENSION_VALIDATION_STATE) => boolean;
  isWarningDimension: (state: Constants.DIMENSION_VALIDATION_STATE) => boolean;
  getCorrectedWeight: (weight: number, prevWeight: number) => any;
  getCustom: (
    score: number | string,
    indicators: IndicatorInfo | IndicatorChoice[] | { [key: string]: { name: string } } | { [x: string]: LevelInfo },
    scaleType?: Constants.ANSWER_TYPES,
  ) => LevelInfo;
  getUserScores: (statement: AssessmentStatement) => IScoreDetailList;
  getLevelName: (level: number) => string;
  isModerator: (dimension: Dimension, user: User, baseView: BASE_CATALOG_VIEW_TYPES) => boolean;
  getModerators: (dimension: Dimension, baseView?: BASE_CATALOG_VIEW_TYPES) => User[];
  lockDimension: (dimension: Dimension, baseView: BASE_CATALOG_VIEW_TYPES) => Dimension;
  publicDimension: (dimension: Dimension, baseView: BASE_CATALOG_VIEW_TYPES) => void;
  setNextOrder: (
    item: Partial<Dimension> | Statement,
    prevOrder: number,
  ) => Partial<Dimension> | Statement;
  handleArchivedStructure: () => boolean;
  showArchivedStructure: () => boolean;
  onItemUp: <T>(
    items: StatementList | DimensionList,
    itemId: string,
    callback: (orderMap: { [key: string]: number }, statements: T) => void,
  ) => void;
  onItemDown: <T>(
    items: StatementList | DimensionList,
    itemId: string,
    callback: (orderMap: { [key: string]: number }, statements: T) => void,
  ) => void;
}

function CatalogFactory(): CatalogServiceInterface {
  function modifyCatalogState(item: Dimension, catalogState: CATALOG_STATE_TYPES) {
    item.catalogState = catalogState;

    updateCatalogState(item.dimensions, item.catalogState);
    updateCatalogState(item.statements, item.catalogState);

    return item;
  }

  function updateCatalogState(
    list: DimensionList | StatementList,
    catalogState: CATALOG_STATE_TYPES,
  ) {
    if (JSService.getObjectLength(list)) {
      Object.keys(list).forEach((itemId) => {
        const item = list[itemId];
        item.catalogState = catalogState;

        updateCatalogState((item as Dimension)?.dimensions, catalogState);
        updateCatalogState((item as Dimension)?.statements, catalogState);
      });
    }
  }

  function indicators(score: number | string, scaleType: Constants.ANSWER_TYPES) {
    const type = scaleType || Constants.SCALE_TYPE.RATING.value;
    const indicator =
      Constants.SCORE_SCALE[type][
        ScaleService.isRating(type) ? ScoreColorService.getNumber(score) : score
      ];
    const scoreLabel = indicator && indicator.label ? indicator.label.toUpperCase() : 'N/A';
    return {
      [scoreLabel]: Constants.DEFAULT_INDICATORS[type][scoreLabel] || Constants.NA_INDICATOR,
    };
  }

  function isArchived(state: CATALOG_STATE_TYPES) {
    if (!state) return false;
    return state === Constants.CATALOG_STATE.ARCHIVED;
  }

  function isDimensionType(type: CATALOG_STATE_TYPES) {
    return type === Constants.CATALOG_LIST_TYPE.DIMENSION;
  }

  function isInvalidDimension(state: Constants.DIMENSION_VALIDATION_STATE) {
    return state === Constants.DIMENSION_VALIDATION_STATE.INVALID;
  }

  function isWarningDimension(state: Constants.DIMENSION_VALIDATION_STATE) {
    return state === Constants.DIMENSION_VALIDATION_STATE.WARNING;
  }

  function isPublic(dimension: Dimension) {
    return dimension.public === true;
  }

  function isSystem(dimension: Dimension) {
    return dimension.type === Constants.DIMENSION_TYPES.CORE;
  }

  function getCorrectedWeight(weight: number, prevWeight: number) {
    if (!Number.isNaN(weight)) {
      if (weight === 0) {
        return Constants.DEFAULT_WEIGHT;
      }
      if (weight < 0) {
        return Math.abs(weight);
      }
      return weight;
    }
    return prevWeight;
  }

  function getCustom(
    score: number | string,
    indicators: IndicatorInfo | IndicatorChoice[] | { [key: string]: { name: string } } | { [x: string]: LevelInfo },
    scaleType?: Constants.ANSWER_TYPES,
  ) {
    const type = scaleType || Constants.SCALE_TYPE.RATING.value;
    const value =
      !ScaleService.isRating(type) || score === 'null' ? score : ScoreColorService.getNumber(score);
    const scoreName = Constants.SCORE_SCALE[type][value]
      ? Constants.SCORE_SCALE[type][value].label.toUpperCase()
      : value;
    if (indicators[scoreName]) {
      return indicators[scoreName];
    }
  }

  function getLevelName(level: number) {
    switch (level) {
      case 0:
        return Constants.CATALOG_LIST_TYPE.TEMPLATE;
      case 1:
        return Constants.CATALOG_LIST_TYPE.DIMENSION;
      case 2:
        return Constants.CATALOG_LIST_TYPE.SUB_DIMENSION;
      case 3:
        return Constants.CATALOG_LIST_TYPE.SUB_SUB_DIMENSION;
      default:
        return 'Unknown level';
    }
  }

  function getUserScores(statement: AssessmentStatement) {
    return Object.assign({}, statement.expertScores || statement.userScores);
  }

  function isModerator(dimension: Dimension, user: User, baseView: BASE_CATALOG_VIEW_TYPES) {
    if (!dimension) {
      return false;
    }
    if (!dimension.moderators || !dimension.moderators.length) {
      const parentDimension = dimension.parentId && findDimension(dimension.parentId, baseView);

      return isModerator(parentDimension as Dimension, user, baseView);
    }
    return dimension.moderators.some((moderator) => moderator.id === user?.id);
  }

  function getModerators(dimension: Dimension, baseView?: BASE_CATALOG_VIEW_TYPES) {
    if (!dimension) {
      return [];
    }
    if (!dimension.moderators?.length) {
      const parentDimension = dimension.parentId && findDimension(dimension.parentId, baseView);

      return getModerators(parentDimension as Dimension, baseView);
    }
    return dimension.moderators;
  }

  function lockDimension(dimension: Dimension, baseView: BASE_CATALOG_VIEW_TYPES) {
    const locked = !dimension.locked;
    return {
      ...dimension,
      locked,
      dimensions: lockDimensionChildren(dimension.dimensions, locked, baseView),
    };
  }

  function lockDimensionChildren(
    list: DimensionList,
    locked: boolean,
    baseView: BASE_CATALOG_VIEW_TYPES,
  ) {
    if (JSService.getObjectLength(list)) {
      Object.keys(list).forEach((itemId) => {
        const item = list[itemId];
        const parentItem = item.parentId && findDimension(item.parentId, baseView) as Dimension;

        (item as Dimension).locked = locked;
        if (parentItem) {
          parentItem.locked = locked;
        }

        return {
          ...item,
          dimensions: lockDimensionChildren(item.dimensions, locked, baseView),
        };
      });
    }
    return list;
  }

  function publicDimension(dimension: Dimension, baseView: BASE_CATALOG_VIEW_TYPES) {
    dimension.public = !dimension.public;

    publicDimensionChildren(dimension.dimensions, dimension.public, baseView);
    publicDimensionChildren(dimension.statements, dimension.public, baseView);
  }

  function publicDimensionChildren(
    list: DimensionList | StatementList,
    publicValue: boolean,
    baseView: BASE_CATALOG_VIEW_TYPES,
  ) {
    if (JSService.getObjectLength(list)) {
      Object.keys(list).forEach((itemId) => {
        const item = list[itemId];
        const parentItem = item.parentId && findDimension(item.parentId, baseView) as Dimension;

        item.public = publicValue;
        if (parentItem) {
          parentItem.public = publicValue;
        }

        publicDimensionChildren((item as Dimension)?.dimensions, publicValue, baseView);
        publicDimensionChildren((item as Dimension)?.statements, publicValue, baseView);
      });
    }
  }

  function setNextOrder(item: Partial<Dimension> | Statement, prevOrder: number) {
    item.order = prevOrder + 1;

    return item;
  }

  function handleArchivedStructure() {
    const archivedState =
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE) === CONDITION_STRINGS.FALSE
        ? CONDITION_STRINGS.TRUE
        : CONDITION_STRINGS.FALSE;
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE, archivedState);

    return archivedState === CONDITION_STRINGS.TRUE;
  }

  function showArchivedStructure() {
    return (
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE) === CONDITION_STRINGS.TRUE
    );
  }

  function onItemUp<T>(
    items: StatementList | DimensionList,
    itemId: string,
    callback: (orderMap: { [key: string]: number }, statements: T) => void,
  ) {
    const order = items[itemId].order;
    const orders = Object.values(items).map((value) => value.order);
    const prevOrderArray = orders.filter((orderEl) => orderEl < order);
    const prevOrder = prevOrderArray?.length > 0 ? Math.max.apply(null, prevOrderArray) : null;
    const prevItemId = getItemByOrder(items, prevOrder as number);

    if (prevItemId) {
      const reorderedItems = { ...items };
      reorderedItems[prevItemId].order = order;
      reorderedItems[itemId].order = prevOrder as number;

      onItemsOrder(reorderedItems[prevItemId], reorderedItems[itemId], reorderedItems, callback);
    }
  }

  function onItemDown<T>(
    items: StatementList | DimensionList,
    itemId: string,
    callback: (orderMap: { [key: string]: number }, statements: T) => void,
  ) {
    const order = items[itemId].order;
    const orders = Object.values(items).map((value) => value.order);
    const nextOrderArray = orders.filter((orderEl) => orderEl > order);
    const nextOrder = nextOrderArray?.length > 0 ? Math.min(...nextOrderArray) : null;
    const nextItemId = getItemByOrder(items, nextOrder as number);

    if (nextItemId) {
      const reorderedItems = { ...items };
      reorderedItems[nextItemId].order = order;
      reorderedItems[itemId].order = nextOrder as number;

      onItemsOrder(reorderedItems[nextItemId], reorderedItems[itemId], reorderedItems, callback);
    }
  }

  function onItemsOrder<T>(
    itemA: Statement | Dimension,
    itemB: Statement | Dimension,
    items: StatementList | DimensionList,
    callback: (orderMap: { [key: string]: number }, items: T) => void,
  ) {
    const orderMap: { [key: string]: number } = {};
    orderMap[itemA.id] = itemA.order;
    orderMap[itemB.id] = itemB.order;

    callback(orderMap, items as T);
  }

  function getItemByOrder(items: StatementList | DimensionList, order: number) {
    return Object.keys(items).find((itemId) => items[itemId].order === order);
  }

  return {
    modifyCatalogState,
    indicators,
    isArchived,
    isPublic,
    isSystem,
    isDimensionType,
    isInvalidDimension,
    isWarningDimension,
    getCorrectedWeight,
    getCustom,
    getUserScores,
    getLevelName,
    isModerator,
    getModerators,
    lockDimension,
    publicDimension,
    setNextOrder,
    handleArchivedStructure,
    showArchivedStructure,
    onItemUp,
    onItemDown,
  };
}

export const CatalogService: CatalogServiceInterface = CatalogFactory();
