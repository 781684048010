/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  TextColorPalette,
  MainColorPalette,
  sourceSansPro,
  boxShadowColor,
} from '@utils/variables';

export const dummyResult = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const resultPaper = css`
  min-height: 543px;
  justify-content: center;
  box-shadow: 0 2px 10px ${boxShadowColor};
  background: white;
  border-radius: 6px;
  margin-top: 1.5rem !important;
`;

export const withAdditionalPadding = css`
  padding: 66px 24px 53px;
`;

export const dummyResultAsset = css`
  margin-bottom: 30px;
`;

export const dummyResultText = css`
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
  font-weight: 600;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Primary};
`;

export const dummyResultDescription = css`
  margin-top: 9px;
  display: inline-block;
  line-height: 20px;
  font-size: 16px;
  font-family: ${sourceSansPro};
  color: ${MainColorPalette.Grey[400]};
`;

export const dummyResultButtonWrapper = css`
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: flex-end;
  margin-right: 24px;
`;

export const buttonsSection = css`
  display: flex;
  gap: 10px;
  margin-top: 30px;
`;
