/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useContext } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { COOKIE_LAW_COOKIE_NAME } from '@lib/cookie-law.constants';
import { AMPLITUDE_API_QUERY_KEY } from '@services/analytics/analytics.constants';
import { AnalyticsContext } from '@services/analytics/analytics.context';

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);
  analytics.setConcentState(document.cookie.includes(COOKIE_LAW_COOKIE_NAME));

  useSuspenseQuery({
    queryKey: [AMPLITUDE_API_QUERY_KEY],
    queryFn: async () => analytics.init(),
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return analytics;
};
