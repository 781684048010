/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosRequestConfig } from 'axios';
import { handleRequest } from '@api/api.utils';
import { BASE_URL } from '@lib/common.constants';
import { Template } from '@root/src/types/template.types';
import { HTTP_METHODS } from '@api/api.types';

export interface TemplateAPIFactoryInterface {
  getAll(): Promise<Template[]>;
  get(templateId: string): Promise<Template>;
  save(template: Template): Promise<Template>;
  update(templateId: string, template: Template): Promise<Template>;
  delete(templateId: string): Promise<void>;
  exportProgress(templateId: string): Promise<void>;
  exportResults(templateId: string): Promise<void>;
  exist(templateName: string): Promise<boolean>;
}

function createTemplateAPI(): TemplateAPIFactoryInterface {
  const abortControllers: Record<string, AbortController> = {};

  const handleTemplateRequest = (requestOptions: AxiosRequestConfig) =>
    handleRequest(requestOptions, abortControllers);

  return {
    getAll: () =>
      handleTemplateRequest({
        method: HTTP_METHODS.GET,
        url: `${BASE_URL}/templates`,
      }),
    get: (templateId) =>
      handleTemplateRequest({
        method: HTTP_METHODS.GET,
        url: `${BASE_URL}/templates/${templateId}`,
      }),
    save: (template) =>
      handleTemplateRequest({
        method: HTTP_METHODS.POST,
        url: `${BASE_URL}/templates`,
        data: template,
      }),
    update: (templateId, template) =>
      handleTemplateRequest({
        method: HTTP_METHODS.PUT,
        url: `${BASE_URL}/templates/${templateId}`,
        data: template,
      }),
    delete: (templateId) =>
      handleTemplateRequest({
        method: HTTP_METHODS.DELETE,
        url: `${BASE_URL}/templates/${templateId}`,
      }),
    exportProgress: (templateId) =>
      handleTemplateRequest({
        method: HTTP_METHODS.GET,
        url: `${BASE_URL}/report/template/${templateId}/progress`,
      }),
    exportResults: (templateId) =>
      handleTemplateRequest({
        method: HTTP_METHODS.GET,
        url: `${BASE_URL}/report/template/${templateId}/result`,
      }),
    exist: (templateName) =>
      handleTemplateRequest({
        method: HTTP_METHODS.GET,
        url: `${BASE_URL}/templates/name/${encodeURIComponent(templateName)}`,
      }),
  };
}

export const TemplateAPI = createTemplateAPI();
