/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { BrowserOptions } from '@amplitude/analytics-types';

export enum AnalyticsEventCategories {
  NAVIGATION = 'navigation',
}

export enum AmplitudeUserTypes {
  EPAM = 'epam',
  EXTERNAL = 'non-epam',
}

export enum AnalyticsEventProperties {
  PageName = 'current_page',
  PreviousPageName = 'previous_page',
}

export enum AnalyticsUserProperties {
  PrismReviewRole = 'survey_role',
  PrismTenantId = 'tenant_id',
  PrismUnitRoles = 'prism_unit_role',
  PrismUserId = 'prism_id',
  PrismUserType = 'user_type',
  ScreenHeight = 'screen_height',
  ScreenWidth = 'screen_width',
}

export enum DCAnalyticsUserProperties {
  ExternalId = 'externalId',
  UnitType = 'unitType',
  UserId = 'userId',
}

export const ANALYTICS_EVENT_DEDUPLICATION_THRESHOLD_MS = 1000;
export const ANALYTICS_IGNORED_ROUTES = [/\/admin\/permissions\/?$/, /\/units\/?$/];
export const ANALYTICS_PRODUCT = 'prism';
export const ANALYTICS_UNKNOWN_ROUTE = 'OTHER';
export const ANALYTICS_GENERAL_ROUTE = 'GENERAL';

export const ANALYTICS_PAGES = {
  GENERAL: 'general',
  OTHER: 'other',
  ANONYMOUS_REVIEW: 'anonymous',
  ASSESSMENT: 'assessment',
  CAMPAIGN: 'campaign',
  CATALOG: 'catalog',
  DIMENSION: 'dimension',
  LOGIN: 'login',
  MANAGEMENT_PLUS_PAGE: 'mgmt',
  MANAGEMENT: 'mgmt',
  PERMISSIONS: 'permissions',
  TEMPLATE_NEW: 'edittemplates',
  TEMPLATES: 'templates',
  UNIT_PERMISSIONS_PLUS_PAGE: 'permissions',
  UNITS: 'units',
};
export const ANALYTICS_SKIP_AUTO_TRACKING_PAGES = [
  ANALYTICS_PAGES.ASSESSMENT,
  ANALYTICS_PAGES.LOGIN,
];

export const AMPLITUDE_API_QUERY_KEY = 'amplitude';
export const AMPLITUDE_DEFAULT_SETTINGS: BrowserOptions = {
  appVersion: process.env.VERSION,
  autocapture: false,
  instanceName: ANALYTICS_PRODUCT,
  ...(process.env.NODE_ENV === 'development' && {
    flushMaxRetries: 1,
    // logLevel: LogLevel.Debug,
    serverUrl: '/api/amplitude/2/httpapi', // send events to Amplitude mock API in development
  }),
};
