/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect } from 'react';
import { IconButton, Paper, Tooltip } from '@perf/ui-components';
import { ChevronDown18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronDown18';
import { ChevronRight18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronRight18';
import { InfoOutline24 } from '@perf/ui-components/dist/icons/uui/notification/InfoOutline24';
import { dimensionsSurveyStyles, scrollMargin } from './dimensions-survey.style';
import { ReviewCatalogAPI } from '@api/services/review-api.resources';
import COMMENT_ICON from '@app/img/svg/comment.svg';
import COMMENT_ICON_ACTIVE from '@app/img/svg/comment_active.svg';
import Info from '@components/common/info/info.component';
import StatementsSurvey from '@components/common/statements-survey/statements-survey.component';
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { ReviewService } from '@services/review.service';
import { assessmentTabsHeight, headerHeight, navbarHeight } from '@utils/variables';
import { User } from '@root/src/types/user.types';
import { AssessmentStatement, CatalogDimension, IDimensionSummary, IVoter } from '@root/src/types/catalog.types';
import { APP_REVIEW_ROLES, MESSAGE, SUMMARY_DEFAULT_TEXT } from '@root/src/lib/common.constants';
import { getStatementCount } from '@root/src/utils/review-helpers.utils';
import { setAssessmentState, setProgress } from '@root/src/store/assessment.actions';
import { setAnonymsAssessmentState, setAnonymsProgress } from '@root/src/store/anonyms-assessment.actions';
import { AnonymsAssessmentActions, useAnonymsAssessmentStore } from '@root/src/store/use.anonyms-assessment.store';
import { AssessmentActions, useAssessmentStore } from '@root/src/store/use.assessment.store';
import { JSService } from '@root/src/services/js.service';
import { getIDsOfDimensions, setSelectedStatementCount, updateReviewDimensions } from '@components/views/assessment-view/assessment-view.utils';

const SCROLL_MARGIN = (tabs: boolean) =>
  headerHeight + navbarHeight + (tabs ? assessmentTabsHeight : 0) + 6; // scroll delta

interface DimensionsSurveyProps {
  dimensions?: CatalogDimension[];
  user: User;
  roles: APP_REVIEW_ROLES[];
  voter: IVoter | User;
  level: number;
  isEditable: boolean;
  isSummaryVisible: boolean;
  onProgressCalc?: (selectedCount: number, count: number) => void;
  anonymous?: boolean;
  onToggle?: (dimensions: CatalogDimension[]) => void;
  isDocumentsAvailable: boolean;
  isTabs?: boolean;
  reviewId: string;
  isFinished: boolean;
  onUpdateDimensionSummary?: (dimensions: CatalogDimension, summary: IDimensionSummary) => void;
  isPreview?: boolean;
  isReviewDataPrepared?: boolean;
}

const DimensionsSurvey = ({
  dimensions: dimensionsProps,
  level,
  isSummaryVisible,
  isEditable,
  anonymous,
  isDocumentsAvailable,
  isTabs,
  reviewId,
  isFinished,
  user,
  roles,
  voter,
  onToggle,
  onProgressCalc,
  onUpdateDimensionSummary,
  isPreview,
  isReviewDataPrepared,
}: DimensionsSurveyProps) => {
  const currentStoreSetter = anonymous ? setAnonymsAssessmentState : setAssessmentState;
  const currentStoreGetter = anonymous ? useAnonymsAssessmentStore.getState : useAssessmentStore.getState;

  const assessmentStore = useAssessmentStore((state) => ({
    openedIDs: state.assessmentState.openedIDs,
  }));

  const anonymsAssessmentStore = useAnonymsAssessmentStore((state) => ({
    openedIDs: state.assessmentState.openedIDs,
  }));

  const { openedIDs } = anonymous ? anonymsAssessmentStore : assessmentStore;
  const handleProgress = (ignoreSave?: boolean, updatedStatements?: AssessmentStatement[], dimensionId?: string) => anonymous ? setAnonymsProgress(ignoreSave, updatedStatements, dimensionId) : setProgress(ignoreSave, updatedStatements, dimensionId, false);

  useEffect(() => {
    if (isReviewDataPrepared || isPreview) {
      componentOnInit();
    }
  }, [isReviewDataPrepared]);

  const getTargetDimensions = () => {
    const review = currentStoreGetter().assessmentState.review;

    return JSService.isEmptyOrNullObject(dimensionsProps) ? structuredClone(review?.dimensions) : dimensionsProps as CatalogDimension[];
  };

  const getTopLevelDimensions = () => {
    const review = currentStoreGetter().assessmentState.review;

    return JSService.isEmptyOrNullObject(review.dimensions) ? dimensionsProps as CatalogDimension[] : structuredClone(review.dimensions);
  };

  const setDimensionToStore = (updatedDimensions: CatalogDimension[]) => {
    const { review } = currentStoreGetter().assessmentState;
    const action = anonymous ? AnonymsAssessmentActions.setReview : AssessmentActions.setReview;

    currentStoreSetter({
      review: {
        ...review,
        dimensions: setSelectedStatementCount(updatedDimensions) as CatalogDimension[],
      },
    }, action as never);
  };

  const componentOnInit = () => {
    const targetDimensions = getTargetDimensions();

    if (targetDimensions?.length) {
      setStatementCount(Object.values(targetDimensions));
    }
  };

  const closeChildren = (dimension: CatalogDimension, openedIDs: string[]) => {
    const closedDimensionIDs = [dimension.id, ...getIDsOfDimensions(dimension.dimensions)];
    const action = anonymous ? AnonymsAssessmentActions.setReview : AssessmentActions.setReview;
    const updatedIDs = openedIDs.filter(id => !closedDimensionIDs.includes(id));

    currentStoreSetter({
      openedIDs: updatedIDs,
    }, action as never);
  };

  const openChildren = (dimension: CatalogDimension, openedIDs: string[]) => {
    const action = anonymous ? AnonymsAssessmentActions.setReview : AssessmentActions.setReview;
    const updatedIDs = [...new Set([...openedIDs, dimension.id])];

    currentStoreSetter({
      openedIDs: updatedIDs,
    }, action as never);
  };

  const activateChildren = (dimensionIndex: number) => {
    const updatedDimensions = getTargetDimensions();
    const dimension = updatedDimensions[dimensionIndex];
    const isOpened = !openedIDs.includes(dimension.id);

    if (!isOpened) {
      closeChildren(dimension, openedIDs);
    } else {
      openChildren(dimension, openedIDs)
    }
    onToggle?.(updatedDimensions);
  };

  const hasChildren = (dimension: CatalogDimension) =>
    !!(
      Object.keys(dimension.dimensions || [])?.length ||
      Object.keys(dimension.statements || [])?.length
    );

  const hasSummary = (dimension: CatalogDimension) => !!(dimension.summary && dimension.summary.text);

  const isFullVoted = (dimension: CatalogDimension) => dimension.selectedStatementCount === dimension.statementLength;

  const isSummaryEditable = () =>
    isEditable && !roles.includes(APP_REVIEW_ROLES.REVIEW_CLIENT);

  const getNextLevel = () => level + 1;

  const saveSummary = (dimensionIndex: number, summary: IDimensionSummary, summaryId: string) => {
    const updatedDimensions = structuredClone(getTargetDimensions());

    if (
      !summary.text &&
      (!updatedDimensions[dimensionIndex].summary ||
        !updatedDimensions[dimensionIndex].summary.text)
    ) {
      return;
    }

    if (summaryId) {
      summary.id = summaryId;
    }

    delete summary?.date;

    ReviewCatalogAPI.summary(updatedDimensions[dimensionIndex].id, summary)
      .then((res) => {
        updatedDimensions[dimensionIndex].summary = res;

        onUpdateDimensionSummary?.(updatedDimensions[dimensionIndex], res);
        NotificationService.showSuccess(MESSAGE.SUCCESS.COMMENT.SAVE);
      })
      .catch(ReviewService.showError);
  };

  const setStatementCount = (dimensionsToUpdate: CatalogDimension[]) => {
    if (!dimensionsToUpdate || !dimensionsToUpdate.length) {
      return;
    }
    const dimensionsWithCount = dimensionsToUpdate.map((dimension) => {
      dimension.statementLength = getStatementCount(dimension);

      return dimension;
    });

    const topLevelDimensions = getTopLevelDimensions();

    const updatedDimensions = updateReviewDimensions(
      dimensionsWithCount,
      topLevelDimensions,
    );
    setDimensionToStore(updatedDimensions);
  };

  const showSummary = (dimensionIndex: number) => {
    if (!isSummaryEditable()) {
      return;
    }

    const dimension = getTargetDimensions()[dimensionIndex];
    const summary = dimension.summary ? dimension.summary.text : '';
    const summaryId = dimension.summary?.id;

    ModalService.openDetailsModal({
      title: `comments and recommendations to "${dimension.name}"`,
      placeholder: 'Type comment and recommendation here',
      text: summary,
      defaultText: SUMMARY_DEFAULT_TEXT,
      onAccept: (targetSummary: IDimensionSummary) => saveSummary(dimensionIndex, targetSummary, summaryId || ''),
    });
  };

  return (
    <section
      css={(level === 2 || level === 3) && dimensionsSurveyStyles.subDimensions}
    >
      {!!getTargetDimensions()?.length &&
        getTargetDimensions()
          .sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
          .map(
            (dimension, dimensionIndex) => {
              const { openedIDs } = currentStoreGetter().assessmentState;
              const isOpened = openedIDs.includes(dimension.id);

              return (
                hasChildren(dimension) && (
                  <section key={dimensionIndex} >
                    <div css={dimensionsSurveyStyles.dimensionWrap}>
                      <span css={dimensionsSurveyStyles.dimensionIcon}>
                        <IconButton onClick={() => activateChildren(dimensionIndex)}>
                          {isOpened? <ChevronDown18 /> : <ChevronRight18 />}
                        </IconButton>
                      </span>
                      <Paper css={dimensionsSurveyStyles.dimensionPreWrap}>
                        <section
                          css={[
                            dimensionsSurveyStyles.assessmentDimension,
                            scrollMargin(SCROLL_MARGIN(!!isTabs)),
                          ]}
                          id={`asmt-dimension-${dimension.id}`}
                        >
                          <p css={dimensionsSurveyStyles.dimensionName}>{dimension.name}</p>
                          <div css={dimensionsSurveyStyles.dimensionControls}>
                            <span css={dimensionsSurveyStyles.statementCount}>
                              <span
                                css={isFullVoted(dimension) ? dimensionsSurveyStyles.statementCountFull : null}
                              >
                                {dimension.selectedStatementCount || 0}
                              </span>
                              <span> / {dimension.statementLength || dimension?.statements?.length}</span>
                            </span>
                            {isSummaryVisible && (
                              <IconButton
                                onClick={() => showSummary(dimensionIndex)}
                                css={dimensionsSurveyStyles.dimensionInfoIcon}
                                disabled={!isSummaryEditable()}
                              >
                                <img
                                  src={hasSummary(dimension) ? COMMENT_ICON_ACTIVE : COMMENT_ICON as any}
                                  alt="info-icon"
                                />
                              </IconButton>
                            )}
                            <Tooltip
                              content={<Info indicators={dimension.indicatorInfo} />}
                              placement="left"
                              disabled={!isEditable}
                            >
                              <div css={dimensionsSurveyStyles.dimensionInfoIcon}>
                                <InfoOutline24 color="additional-greyscale400" />
                              </div>
                            </Tooltip>
                          </div>
                        </section>
                        {dimension.statements && isOpened && (
                          <StatementsSurvey
                            initialStatements={dimension.statements}
                            dimensionId={dimension.id}
                            user={user}
                            roles={roles}
                            voter={voter}
                            onProgressSet={handleProgress}
                            isEditable={isEditable}
                            anonymous={!!anonymous}
                            isDocumentsAvailable={isDocumentsAvailable}
                            reviewId={reviewId}
                            isFinished={isFinished}
                          />
                        )}
                      </Paper>
                    </div>
                    {!!Object.values(dimension?.dimensions || [])?.length && isOpened && (
                      <DimensionsSurvey
                        dimensions={dimension.dimensions}
                        user={user}
                        roles={roles}
                        voter={voter}
                        level={getNextLevel()}
                        isSummaryVisible={isSummaryVisible}
                        onProgressCalc={(selectedCount, count) =>
                          onProgressCalc?.(selectedCount, count)
                        }
                        isEditable={isEditable}
                        anonymous={anonymous}
                        onToggle={onToggle}
                        isDocumentsAvailable={isDocumentsAvailable}
                        isTabs={isTabs}
                        reviewId={reviewId}
                        isFinished={isFinished}
                        onUpdateDimensionSummary={onUpdateDimensionSummary}
                        isReviewDataPrepared={isReviewDataPrepared}
                      />
                    )}
                  </section>
                )
              )
            })}
    </section>
  );
};

export default DimensionsSurvey;
