/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { GlobalState } from '@app/store/store.types';
import { useGlobalStore, GlobalActions } from '@app/store/use.global.store';
import { API_REQUEST_KEY } from '@lib/requests.constants';

export const setGlobalState = (updatedGlobalState: Partial<GlobalState>, action: GlobalActions) =>
  useGlobalStore.setState((state) => ({
    ...state,
    ...updatedGlobalState,
  }), false, action);

export const setLoadingStatus = (isLoading: boolean) =>
  useGlobalStore.setState(() => ({ isLoading }), false, GlobalActions.setLoadingStatus);

export const addRequestStatus = (requestKeys: API_REQUEST_KEY | API_REQUEST_KEY[]) => {
  useGlobalStore.setState(({ loadingQueue }) => {
    const keys = Array.isArray(requestKeys) ? requestKeys : [requestKeys];

    const currentRequests = keys.reduce(
      (requestsAcc, key) => (requestsAcc.includes(key) ? requestsAcc : [...requestsAcc, key]),
      loadingQueue,
    );

    return { isLoading: !!currentRequests.length, loadingQueue: currentRequests };
  }, false, GlobalActions.updateLoadingQueue);
};

export const removeRequestStatus = (requestKeys: API_REQUEST_KEY[]) => {
  useGlobalStore.setState(({ loadingQueue }) => {
    const keys = Array.isArray(requestKeys) ? requestKeys : [requestKeys];

    const currentRequests = keys.reduce(
      (requestsAcc, key) =>
        requestsAcc.includes(key) ? requestsAcc.filter((req) => req !== key) : requestsAcc,
      loadingQueue,
    );

    return { isLoading: !!currentRequests.length, loadingQueue: currentRequests };
  }, false, GlobalActions.updateLoadingQueue);
};

export const clearRequestStatus = () => {
  useGlobalStore.setState(() => ({
    isLoading: false,
    loadingQueue: [],
  }), false, GlobalActions.clearRequestStatus);
};
