/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import * as amplitude from '@amplitude/analytics-browser';
import { map, pipe, Subject } from 'rxjs';
import { AmplitudeAnalyticsServiceApi } from '@api/services/amplitude';
import { routes } from '@app/routes/routes.constants';
import {
  AMPLITUDE_DEFAULT_SETTINGS,
  ANALYTICS_EVENT_DEDUPLICATION_THRESHOLD_MS,
  ANALYTICS_IGNORED_ROUTES,
} from '@services/analytics/analytics.constants';
import { filterDuplicateEvents } from '@services/analytics/analytics.operators';
import {
  AnalyticsEvent,
  AnalyticsEventValue,
  IAnalyticsService,
} from '@services/analytics/analytics.types';
import { sortEventProperties } from '@services/analytics/analytics.utils';
import { NavigationService } from '@services/analytics/navigation/analytics-navigation.service';

const processEvent = () =>
  pipe(
    map(sortEventProperties()),
    filterDuplicateEvents(ANALYTICS_EVENT_DEDUPLICATION_THRESHOLD_MS),
  );

export class AnalyticsService implements IAnalyticsService {
  private amplitudeInstance: amplitude.Types.BrowserClient;

  private variables: Record<string, AnalyticsEventValue> = {};

  public navigation: NavigationService;

  private event$: Subject<AnalyticsEvent>;

  constructor(navigationService: NavigationService = new NavigationService(routes, {
    ignoredRoutes: ANALYTICS_IGNORED_ROUTES,
    locationChangeDebounceDelay: 50,
  })) {
    this.amplitudeInstance = amplitude.createInstance();
    this.event$ = new Subject<AnalyticsEvent>();
    this.navigation = navigationService;
  }

  public async init(): Promise<boolean> {
    try {
      const { amplitudeToken } = await AmplitudeAnalyticsServiceApi.getConfig();
      await this.amplitudeInstance.init(amplitudeToken, AMPLITUDE_DEFAULT_SETTINGS).promise;
    } catch (error) {
      console.error('Failed to initialize AnalyticsService:', error);
      return false;
    }

    // Create subscriptions
    this.event$.pipe(processEvent()).subscribe((data) => {
      const { name, properties } = data;
      this.amplitudeInstance.track(name, properties);
    });

    // send events immediately when the user navigates to another page
    document.addEventListener('visibilitychange', () => {
      const transport: amplitude.Types.TransportType = document.hidden ? 'beacon' : 'fetch';

      this.amplitudeInstance.setTransport(transport);

      if (document.hidden) {
        this.amplitudeInstance.flush();
      }
    });

    return true;
  }

  public identify(identity: amplitude.Identify): void {
    this.amplitudeInstance.identify(identity);
  }

  public track(name: string, properties?: Record<string, AnalyticsEventValue>): void {
    this.event$.next({
      name,
      properties: properties as Record<string, AnalyticsEventValue>,
    });
  }

  public getIdentity(): amplitude.Identify {
    return new amplitude.Identify();
  }

  public setEventVariables(
    variables: Record<string, AnalyticsEventValue | null | undefined>,
  ): void {
    for (const [key, value] of Object.entries(variables)) {
      if (value == null) {
        delete this.variables[key];
      } else {
        this.variables[key] = value;
      }
    }
  }

  public setUserId(userId: string | undefined): void {
    this.amplitudeInstance.setUserId(userId);
  }

  public setDeviceId(deviceId: string): void {
    this.amplitudeInstance.setDeviceId(deviceId);
  }

  public setSessionId(sessionId: number): void {
    this.amplitudeInstance.setSessionId(sessionId);
  }

  public setConcentState(accepted: boolean): void {
    this.amplitudeInstance.setOptOut(!accepted);
  }
}
