/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { FeatureToggle } from '@app/types/feature-toggle.types';
import { useFeatureToggleQuery } from '@components/common/feature-toggle/feature-toggle.queries';

export const useFeatureToggleIsOn = (
  featureToggle: FeatureToggle,
  defaultValue = false,
): boolean => {
  const queryResult = useFeatureToggleQuery();

  return queryResult.data?.[featureToggle] ?? defaultValue;
};
