/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useSuspenseQuery } from '@tanstack/react-query';
import { FEATURE_TOGGLE_QUERY_CACHE_KEY } from '@components/common/feature-toggle/feature-toggle.constants';
import { CommonAPI } from '@api/services/common-api.resources';

export const useFeatureToggleQuery = () =>
  useSuspenseQuery({
    queryKey: [FEATURE_TOGGLE_QUERY_CACHE_KEY],
    queryFn: CommonAPI.getFeatureToggle,
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
