/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import CustomDialogModalAction from '@components/modal/accept-modal/custom-dialog-modal-action.component';

interface AcceptModalProps {
  title: string;
  description: string;
  onAccept: () => void;
  confirmText?: string;
  isCustom?: boolean;
}

const AcceptModal = ({ title, description, confirmText, onAccept, isCustom }: AcceptModalProps) => {
  const [show, setShow] = useState(true);

  const handleAccept = () => {
    onAccept?.();
    setShow(false);
  };

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      config={{
        title,
        body: description,
        confirmText: confirmText || 'Yes',
        handleCancel: () => setShow(false),
        handleConfirm: handleAccept,
        ...(isCustom && { CustomDialogActions: CustomDialogModalAction }),
      }}
    />
  );
};

export default AcceptModal;
