/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Global } from '@emotion/react';
import { Box, MuiThemeProvider, theme } from '@perf/ui-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { queryClient } from '@app/api/query.client';
import { globalStyles } from '@app/global.style';
import Routes from '@app/routes/routes';
import { createConfig } from '@app/utils/create-config.utils';
import { createServices } from '@app/utils/create-services.utils';
import { AppProvider } from '@components/context/app-provider/app.provider';
import { CrossPlatformContextProvider } from '@components/context/cross-platform/cross-platform.context';
import CookieLaw from '@components/layout/cookie-law/cookie-law.component';
import { APPLICATION_WRAPPER_ID, GLOBAL_SPINNER_ID } from '@lib/common.constants';
import { AnalyticsProvider } from '@services/analytics/analytics.component';
import APMInitializer from '@services/apm-initializer.service';
import { AnalyticsUserProvider } from '@services/analytics/user/analytics-user.component';

const config = createConfig();
const services = createServices();

const App = () => (
  <Box id={APPLICATION_WRAPPER_ID}>
    <Global styles={globalStyles} />
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme()}>
        <AppProvider config={config} services={services}>
          <APMInitializer />
          <CrossPlatformContextProvider>
            <AnalyticsUserProvider>
              <Router>
                {/* Connecting Analytics */}
                <AnalyticsProvider>
                  <Routes />
                </AnalyticsProvider>
              </Router>
            </AnalyticsUserProvider>
          </CrossPlatformContextProvider>
        </AppProvider>
        <CookieLaw />
        <div id={GLOBAL_SPINNER_ID} />
      </MuiThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Box>
);

export default App;
