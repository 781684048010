/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createTheme, styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { AdditionalColorPalette, whiteColor } from '@root/src/utils/variables';
import { convertHexToRGBA } from '@root/src/utils/color-replace.utils';

export const theme = createTheme({
  components: {
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '12px 24px 12px 5px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
          width: 'fit-content',
        },
      },
    },
  },
});

export const simpleTheme = createTheme({
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
          width: 'fit-content',
        },
      },
    },
  },
});

export const QontoConnector = styled(StepConnector)();

const previewModeSizeConfig = () => {
  const maxWidthMediaW = 1050;
  const maxWidthMediaM = 900;
  const maxWidthMediaS = 750;
  const maxWidthMediaXS = 650;

  return {
    top: 10,
    left: 'calc(-93%)',
    right: 'calc(89% - 1px)',
    alignItems: 'start',
    minWidth: '80px',
    [`@media (max-width: ${maxWidthMediaW}px)`]: {
      left: 'calc(-91% - 1px)',
      right: 'calc(83% + 4px)',
    },
    [`@media (max-width: ${maxWidthMediaM}px)`]: {
      left: 'calc(-89% - 1px)',
      right: 'calc(83%)',
    },
    [`@media (max-width: ${maxWidthMediaS}px)`]: {
      left: 'calc(-87%)',
      right: 'calc(79%)',
    },
    [`@media (max-width: ${maxWidthMediaXS}px)`]: {
      left: 'calc(-89%)',
      right: 'calc(80%)',
    },
  };
};

const asmtModeSizeConfig = () => {
  const maxWidthMediaW = 1390;
  const maxWidthMediaM = 1250;
  const maxWidthMediaS = 1120;
  const maxWidthMediaXS = 1020;
  const maxWidthMediaXXS = 980;

  return {
    top: 10,
    left: 'calc(-92%)',
    right: 'calc(90%)',
    alignItems: 'start',
    minWidth: '80px',
    [`@media (max-width: ${maxWidthMediaW}px)`]: {
      left: 'calc(-91%)',
      right: 'calc(89% - 1px)',
    },
    [`@media (max-width: ${maxWidthMediaM}px)`]: {
      left: 'calc(-89%)',
      right: 'calc(89% - 2px)',
    },
    [`@media (max-width: ${maxWidthMediaS}px)`]: {
      left: 'calc(-88%)',
      right: 'calc(86% - 2px)',
    },
    [`@media (max-width: ${maxWidthMediaXS}px)`]: {
      left: 'calc(-87%)',
      right: 'calc(83%)',
    },
    [`@media (max-width: ${maxWidthMediaXXS}px)`]: {
      left: 'calc(-85%)',
      right: 'calc(83%)',
    },
  };
};

const asmtSuveyModeSizeConfig = () => {
  const maxWidthMediaW = 1390;
  const maxWidthMediaM = 1250;
  const maxWidthMediaS = 1120;
  const maxWidthMediaXS = 1020;
  const maxWidthMediaXXS = 980;
  const maxWidthMediaXXXS = 820;
  const perc90 = 'calc(90%)';

  return {
    top: 10,
    left: 'calc(-94%)',
    right: perc90,
    alignItems: 'start',
    minWidth: '80px',
    [`@media (max-width: ${maxWidthMediaW}px)`]: {
      left: 'calc(-94%)',
      right: 'calc(90% - 1px)',
    },
    [`@media (max-width: ${maxWidthMediaM}px)`]: {
      left: 'calc(-93%)',
      right: 'calc(90% - 2px)',
    },
    [`@media (max-width: ${maxWidthMediaS}px)`]: {
      left: 'calc(-92%)',
      right: 'calc(88% - 2px)',
    },
    [`@media (max-width: ${maxWidthMediaXS}px)`]: {
      left: perc90,
      right: 'calc(85%)',
    },
    [`@media (max-width: ${maxWidthMediaXXS}px)`]: {
      left: perc90,
      right: 'calc(84%)',
    },
    [`@media (max-width: ${maxWidthMediaXXXS}px)`]: {
      left: 'calc(-91%)',
      right: 'calc(83%)',
    },
  };
};

const simpleModeSizeConfig = (width: number | undefined) => {
  const baseStyles = {
    top: 7,
    alignItems: 'start',
    minWidth: '40px',
  };

  if (width && width < 250) {
    return {
      ...baseStyles,
      left: 'calc(-80%)',
      right: 'calc(81%)',
    };
  }


  if (width && width < 330) {
    return {
      ...baseStyles,
      left: 'calc(-82%)',
      right: 'calc(81%)',
    };
  }

  if (width && width < 410) {
    return {
      ...baseStyles,
      left: 'calc(-86%)',
      right: 'calc(86%)',
    };
  }

  return {
    ...baseStyles,
    left: 'calc(-90%)',
    right: 'calc(86%)',
  };
};

const getAsmtConfig = (isSurveyType: boolean, isSimpleScale?: boolean, width?: number) => {
  if (isSimpleScale) {
    return simpleModeSizeConfig(width);
  }
  return isSurveyType ? asmtSuveyModeSizeConfig() : asmtModeSizeConfig();
};

export const QontoConnectorWrapper = ({
  currentColor,
  previewMode,
  disabled,
  isSurveyType,
  isSimpleScale,
  width,
  ...props
}: {
  currentColor: string,
  previewMode?: boolean,
  disabled?: boolean,
  isSurveyType?: boolean,
  isSimpleScale?: boolean,
  width?: number,
}) => {
  const ColorConnector = styled(QontoConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: previewMode ? previewModeSizeConfig() : getAsmtConfig(!!isSurveyType, isSimpleScale, width),
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: disabled ? convertHexToRGBA(currentColor, 0.6) : currentColor,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: disabled ? convertHexToRGBA(currentColor, 0.6) : currentColor,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: AdditionalColorPalette.Greyscale[300],
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return <ColorConnector {...props} />;
};

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean, currentColor: string, disabled: boolean, isSimpleScale?: boolean } }>(
  ({ ownerState }) => ({
    color: AdditionalColorPalette.Greyscale[300],
    display: 'flex',
    height: ownerState.isSimpleScale ? 17 : 22,
    width: ownerState.isSimpleScale ? 17 : 22,
    alignItems: 'center',
    cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
    ...(ownerState.active && {
      border: `3px solid ${ownerState.disabled ? convertHexToRGBA(ownerState.currentColor, 0.6) : ownerState.currentColor}`,
      borderRadius: '50%',
      backgroundColor: whiteColor,
      zIndex: 1,
      fontSize: 18,
      width: ownerState.isSimpleScale ? 17 : 22,
      height: ownerState.isSimpleScale ? 17 : 22,
      color: whiteColor,
      marginTop: '1px',
    }),
    '& .QontoStepIcon-completedIcon': {
      backgroundColor: ownerState.disabled ? convertHexToRGBA(ownerState.currentColor, 0.6) : ownerState.currentColor,
      border: `1px solid ${ownerState.disabled ? convertHexToRGBA(ownerState.currentColor, 0.6) : ownerState.currentColor}`,
      width: 8,
      height: 8,
      borderRadius: '50%',
      zIndex: 1,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      position: 'relative',
      left: ownerState.isSimpleScale ? '0' : '4px',
    },
  }),
);

export const StyledSimpleStep = styled(Step)({
  maxWidth: '140px',
  minWidth: '40px',
});

export const StyledStep = styled(Step)({
  maxWidth: '200px',
  minWidth: '80px',
});

export const QontoStepIcon = (
  props: StepIconProps,
  currentColor: string,
  activeStep: number | undefined,
  disabled: boolean,
  isSimpleScale?: boolean,
) => {
  const { active: propsActive, completed, className } = props;

  const active = propsActive && activeStep !== undefined;

  return (
    <QontoStepIconRoot ownerState={{ active, currentColor, disabled, isSimpleScale }} className={className}>
      {completed && activeStep ? (
        <div className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
