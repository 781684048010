/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PropsWithChildren, Suspense, useCallback, useEffect, useMemo } from 'react';
import { FeatureToggle } from '@app/types/feature-toggle.types';
import { useFeatureToggleIsOn } from '@components/common/feature-toggle/use-feature-toggle-is-on';
import { useGlobalStore } from '@app/store/use.global.store';
import { useStructureStore } from '@app/store/use.structure.store';
import { useConfig } from '@components/context/config/config.context';
import {
  AnalyticsUserProperties,
  DCAnalyticsUserProperties,
} from '@services/analytics/analytics.constants';
import {
  getDcUserIdentity,
  getPrismUserIdentity,
  setOrRemove,
} from '@services/analytics/analytics.helpers';
import { useAnalytics } from '@services/analytics/analytics.hook';

const AnalyticsUserContextProvider = ({ children }: PropsWithChildren) => {
  const analytics = useAnalytics();

  const { isExternal: isWidget } = useConfig();
  const user = useGlobalStore((state) => state.currentUser);
  const tenantId = useGlobalStore((state) => state.tenantId);
  const unit = useStructureStore((state) => state.unit);
  const dcUser = null;

  const { prismUserId, prismUnitType, prismUnitRole, prismUserType } = useMemo(
    () => getPrismUserIdentity({ user, tenantId, unit }),
    [user, tenantId, unit],
  );
  const { dcUserId, dcHashedUserId } = useMemo(() => getDcUserIdentity(dcUser), [dcUser]);
  const identifyUser = useCallback(() => {
    analytics.setUserId(prismUserId);

    const identity = analytics.getIdentity();

    setOrRemove(identity, AnalyticsUserProperties.ScreenWidth, window.screen.width);
    setOrRemove(identity, AnalyticsUserProperties.ScreenHeight, window.screen.height);

    setOrRemove(identity, AnalyticsUserProperties.PrismTenantId, tenantId);
    setOrRemove(identity, AnalyticsUserProperties.PrismUserId, prismUserId);
    setOrRemove(identity, AnalyticsUserProperties.PrismUserType, prismUserType);

    if (prismUnitRole) {
      identity.postInsert(AnalyticsUserProperties.PrismUnitRoles, prismUnitRole);
    }

    if (isWidget) {
      setOrRemove(identity, DCAnalyticsUserProperties.ExternalId, dcUserId);
      setOrRemove(identity, DCAnalyticsUserProperties.UserId, dcHashedUserId);
      setOrRemove(identity, DCAnalyticsUserProperties.UnitType, prismUnitType);
    }

    analytics.identify(identity);
  }, [
    analytics,
    prismUserId,
    dcHashedUserId,
    dcUserId,
    isWidget,
    prismUnitRole,
    prismUserType,
    tenantId,
    prismUnitType,
  ]);

  useEffect(() => identifyUser(), [identifyUser]);

  return children;
};

export const AnalyticsUserProvider = ({ children }: PropsWithChildren) => {
  const isAmplitudeEnabled = useFeatureToggleIsOn(FeatureToggle.AMPLITUDE);

  return isAmplitudeEnabled ? (
    <Suspense fallback={null}>
      <AnalyticsUserContextProvider>{children}</AnalyticsUserContextProvider>
    </Suspense>
  ) : (
    children
  );
};
