/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export enum FeatureToggle {
  AI_ASSISTANT = 'AI_ASSISTANT',
  AMPLITUDE = 'AMPLITUDE',
  CAMPAIGN_TABLE_VIEW = 'CAMPAIGN_TABLE_VIEW',
  CORE_PERMISSIONS = 'CORE_PERMISSIONS',
  FILE_STORAGE = 'FILE_STORAGE',
  GRANT_ACCESS = 'GRANT_ACCESS',
  OPENAI = 'OPENAI',
  RECOMMENDED_SURVEY = 'RECOMMENDED_SURVEY',
  REVIEW_REPORT_BATCH_EXPORT = 'REVIEW_REPORT_BATCH_EXPORT',
}
