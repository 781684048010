/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Identify } from '@amplitude/analytics-types';
import { User as CoreUser } from '@core/integration';
import { ITenantProduct } from '@app/types/product.types';
import { User } from '@app/types/user.types';
import { APP_ROLES } from '@lib/common.constants';
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { AmplitudeUserTypes } from '@services/analytics/analytics.constants';
import { isNotEmpty } from '@services/analytics/analytics.utils';

export const getDcUserIdentity = (user: CoreUser | null) => ({
  dcUserId: user?.id,
  dcHashedUserId: user?.userHash ?? user?.id,
});

export const getPrismUserIdentity = ({
  tenantId,
  user,
  unit,
}: {
  tenantId: string;
  user: User | null;
  unit: ITenantProduct;
}) => ({
  prismUnitRole: getUnitRole(user, tenantId),
  prismUnitType: unit?.type,
  prismUserId: user?.prismUserHashedId,
  prismUserType: getUserType(user),
});

export const setOrRemove = (identity: Identify, key: string, value: any) => {
  if (value != null) {
    identity.set(key, value);
  } else {
    identity.unset(key);
  }
};

const getUserType = (user: User | null) => {
  if (!user) {
    return;
  }

  return user.external === true ? AmplitudeUserTypes.EXTERNAL : AmplitudeUserTypes.EPAM;
};

const getUnitRole = (user: User | null, tenantId: string): string | undefined => {
  if (!user) {
    return;
  }

  const roles = new Set<APP_ROLES | string | null | undefined>();
  const isGlobalAdmin = AccessDecisionManager.isGlobalAdmin(user);
  const unitRole = AccessDecisionManager.getUnitRole(user.roles);

  if (isGlobalAdmin) {
    roles.add(APP_ROLES.GLOBAL_ADMIN);
  }

  if (unitRole) {
    roles.add(unitRole);
  }

  if (tenantId) {
    user.permissions[tenantId]?.roles.forEach((role) => roles.add(role));
  }

  const unitRoles = Array.from(roles)
    .filter(isNotEmpty)
    .map((role) => role.toLowerCase())
    .sort((a, b) => a.localeCompare(b));

  if (unitRoles.length === 0) {
    console.warn('User has no roles');
  }

  if (unitRoles.length > 1) {
    console.warn('User has multiple roles');
  }

  return unitRoles[0];
};
