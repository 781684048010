/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
// ---FONTS---
export const oswald = 'Oswald, Arial, sans-serif';
export const sourceSansPro = 'Source Sans Pro, Arial, sans-serif';

// ---COLORS---
export const bgColor = '#f5f6fa';

export const darkOrchid = '#8018a0';

export const blackGrayColor = '#212121';
export const darkGrayColor = '#333';
export const mediumGrayColor = '#f5f5f5';
export const lightGrayColor = '#ddd';

export const whiteColor = '#fff';
export const blackColor = '#000';

export const grayDarkColor = '#464547';
export const grayTitleColor = '#666';
export const grayTextColor = '#777';
export const graySemilightColor = '#999';
export const grayLightColor = '#b6b6b6';
export const grayVerylightColor = '#ccc';
export const grayBorderColor = '#dedfe1';
export const grayblueColor = '#f0fafd';
export const grayblueLightColor = '#ebedef';
export const graySecondary = '#6C6F7F';
export const grayInfo = '#5B616F';
export const grayTag = '#D9DBE3';
export const grayScore = '#BDBDBD';

export const blueDirtyColor = '#5a9bae';
export const blueBrightColor = '#39c2d7';
export const blueMiddleColor = '#009CCC';
export const blueVerylightColor = '#E9E9EA';

export const primaryHighlight = '#009ECC';

export const greenButtonColor = '#a3c644';
export const greenButtonHoverColor = '#8cad35';
export const greenPositiveColor = '#4caf50';

export const raspberryButtonColor = '#E4445F';
export const raspberryButtonHoverColor = '#8e244d';

export const redNegativeColor = '#b71c1c';
export const deepRedColor = '#b32323';

// ---BOX SHADOW---
export const boxShadowColor = 'rgba(29, 30, 38, 0.1)';
export const shadow = `0px 1px 2px ${boxShadowColor}`;

// ---GRID---
export const desktop = '1200px';
export const desktopWidth = '1170px';
export const tablet = '992px';
export const tabletWidth = '970px';
export const mobile = '768px';
export const mobileWidth = '750px';

/**
 * colors (redesigned)
 */
export const MainColorPalette = {
  LightBlue: {
    900: '#0086AD',
    800: '#0088CC',
    700: '#16A2D9',
    600: '#30B6DD',
    100: '#C4EAF5',
    50: '#E1F4FA',
  },
  LightGreen: {
    900: '#428024',
    800: '#6FAD2F',
    700: '#9BC837',
    50: '#CCEDD2',
  },
  LightYellow: {
    600: '#FF9000',
    100: '#FFEDC9',
    50: '#FFF8CD',
  },
  Blue: {
    900: '#007BFF',
    400: '#3bafda',
    50: '#EAEDF5',
  },
  Green: {
    800: '#558A00',
    700: '#67A300',
    600: '#33cf4d',
  },
  Amber: {
    900: '#E64C00',
    800: '#F67E00',
    700: '#FCAA00',
  },
  Red: {
    900: '#B32424',
    800: '#CC2929',
    700: '#FA4B4B',
    600: '#E22A2A',
    100: '#FDE1E1',
    50: '#FFF5F5',
  },
  Grey: {
    800: '#686868',
    700: '#525462',
    600: '#565866',
    500: '#6C6F80',
    400: '#9E9E9E',
    300: '#9FA1AE',
    200: '#E1E3EB',
    100: '#D8D8D8',
    50: '#F3F3F3',
  },
};

export const TextColorPalette = {
  Primary: '#474A59',
  Secondary: '#8A8C99',
  Disabled: '#ACAFBF',
};

export const AdditionalColorPalette = {
  Red: {
    900: '#8C0E0E',
    800: '#C01A17',
    700: '#E02C24',
    600: '#F44336',
    500: '#FC6449',
    400: '#FF8561',
    300: '#FFA47F',
    200: '#FFC09E',
    100: '#FFD7BE',
    50: '#FFEADB',
  },
  Pink: {
    900: '#94004C',
    800: '#B00052',
    700: '#CD0053',
    600: '#E91E63',
    500: '#F5527B',
    400: '#FD8197',
    300: '#FFA9B3',
    200: '#FFC6CA',
    100: '#FFD7D9',
    50: '#FFDEDE',
  },
  Purple: {
    900: '#550080',
    800: '#680B8F',
    700: '#8018A0',
    600: '#9C27B0',
    500: '#C047C7',
    400: '#DA76D5',
    300: '#E9A4DF',
    200: '#F4C7EA',
    100: '#FBDAF3',
    50: '#FFDEF6',
  },
  Indigo: {
    900: '#191980',
    800: '#21288D',
    700: '#2E3A9D',
    600: '#3F51B5',
    500: '#6362C9',
    400: '#9D8FE9',
    300: '#CAB5FC',
    200: '#E4CFFF',
    100: '#F1DEFF',
    50: '#F4E6FF',
  },
  Blue: {
    900: '#003399',
    800: '#0453C7',
    700: '#0E74E2',
    600: '#2196F3',
    500: '#32B2FB',
    400: '#4BC9FF',
    300: '#95EAFF',
    200: '#C5F6FF',
    100: '#DCFAFF',
    50: '#F3FDFF',
  },
  Cyan: {
    900: '#145866',
    800: '#1D7586',
    700: '#2791A4',
    600: '#35ADBF',
    500: '#46C8D3',
    400: '#5CE2E3',
    300: '#78EFEA',
    200: '#9BF7F1',
    100: '#C2FCF8',
    50: '#EBFFFD',
  },
  Teal: {
    900: '#11736E',
    800: '#009688',
    700: '#09B9A3',
    600: '#16D1B5',
    500: '#2AE2C1',
    400: '#43ECCB',
    300: '#64F4D5',
    200: '#8CF8E0',
    100: '#B9FBEC',
    50: '#E7FCF7',
  },
  Green: {
    900: '#00661A',
    800: '#1E8F2F',
    700: '#4CAF50',
    600: '#57CB56',
    500: '#67DE61',
    400: '#78EA70',
    300: '#8EF384',
    200: '#A8F9A0',
    100: '#C7FDC2',
    50: '#E8FFE6',
  },
  Lime: {
    900: '#4D6600',
    800: '#627F01',
    700: '#7B9905',
    600: '#97B20E',
    500: '#B3C91F',
    400: '#CDDC39',
    300: '#DDEC5B',
    200: '#E8F681',
    100: '#F0FCA8',
    50: '#F7FFCC',
  },
  Yellow: {
    900: '#E39B0B',
    800: '#F5AA0E',
    700: '#FAB516',
    600: '#FCC425',
    500: '#FDD63B',
    400: '#FEE759',
    300: '#FFF57D',
    200: '#FFFCA4',
    100: '#FFFECC',
    50: '#FFFFF2',
  },
  Amber: {
    900: '#B3530B',
    800: '#D47110',
    700: '#EA8C18',
    600: '#F6A024',
    500: '#FBB035',
    400: '#FDBE4C',
    300: '#FECB69',
    200: '#FFD88E',
    100: '#FFE7B9',
    50: '#FFF7E6',
  },
  Orange: {
    900: '#991900',
    800: '#C63100',
    700: '#E44E00',
    600: '#F46900',
    500: '#FB8200',
    400: '#FF9800',
    300: '#FFB851',
    200: '#FFD091',
    100: '#FFE1BD',
    50: '#FFECD9',
  },
  Grey: {
    1000: '#6B6B6B',
    900: '#17181F',
    800: '#272933',
    700: '#3D404D',
    600: '#4F515C',
    500: '#6C6E7A',
    400: '#81838F',
    300: '#9698A3',
    200: '#B6B8C2',
    100: '#D7D9E0',
    50: '#F5F6FA',
  },
  Greyscale: {
    900: '#1D1E26',
    800: '#303240',
    700: '#474A59',
    600: '#8A8C99',
    550: '#E1E3EA',
    500: '#ACAFBF',
    400: '#CED0DB',
    300: '#E1E3EB',
    200: '#EBEDF5',
    100: '#F5F6FA',
    50: '#FAFAFC',
  },
};

export const documentsGroupHeaderColor = '#FAFAFC';

// ---LAYOUT SIZES---
export const headerHeight = 48;
export const asideWidth = [60, 'px'];
export const footerHeight = [60, 'px'];
export const assessmentTabsHeight = 48;

export const footerHeightMobile = [158, 'px'];
export const navbarHeight = 48;
export const navbarTitleHeight = [48, 'px'];
export const structureWidth = [273, 'px'];
