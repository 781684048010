/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const inputGroupLabel = css`
  margin-bottom: 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  display: flex;
  align-items: center;

  span {
    display: inline-flex;
  }

  svg {
    margin-left: 7px;
  }
`;

export const generalInputField = css`
  position: relative;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${AdditionalColorPalette.Greyscale[800]};
    margin-bottom: 8px;
  }

  input {
    padding: 0 12px;
  }

  .select {
    input {
      padding: 0;
    }
  }
`;

export const inputFieldPassword = {
  showPasswordIcon: css`
    color: #acafbf;
    font-size: 16px;
    position: absolute;
    top: 40px;
    right: 12px;
    cursor: pointer;
  `,
};

export const inputLabelWithHint = css`
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
  }
`;

// For row of inputs within group (radio choices, ranges, additional options, supportings questions)
export const inputGroupRowStyles = css`
  display: flex;
  margin-bottom: 8px;
`;
export const inputGroupRowRemoveIcon = css`
  margin: 6px 0 0 17px !important;

  &:disabled {
    visibility: hidden;
  }
`;

/* CHOICES (radio choices, ranges, additional options) */

// For radio choices hover effects
export const radioChoiceItem = css`
  &:hover {
    .dragabble-icon {
      opacity: 1;
      transition: 0s;
    }
  }
`;

// For wrappers of text fields
export const inputWrapperOfChoice = ({ isCenter }: { isCenter: boolean }) => css`
  display: flex;
  position: relative;
  flex-grow: 1;
  margin-right: 29px;
  align-items: ${isCenter ? 'center' : 'flex-start'};

  .circle-decorative {
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 1px solid #ced0db;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 8px;
  }

  .checkbox-decorative {
    min-width: 18px;
    min-height: 18px;
    background: #fff;
    border: 1px solid #ced0db;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 8px;
  }

  .dragabble-icon {
    opacity: 0;
    transition: 0.2s ease-out;
  }
`;

// For wrapper of mapped score select
export const inputForMappedScore = css`
  position: relative;
  display: flex;
  width: 70px;
  /* stylelint-disable-next-line */
  &::before {
    content: '-';
    font-weight: 600;
    font-size: 14px;
    color: ${AdditionalColorPalette.Greyscale[800]};
    position: absolute;
    left: -17px;
    top: 8px;
  }
`;
