/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Button, } from '@perf/ui-components';
import { CustomDialogActionsProps } from '@perf/ui-components/dist/ModalDialog';

const CustomDialogModalAction = ({
  confirmText = 'Ok',
  disableSave = false,
  buttonsSize = 'medium',
  className,
  handleConfirm,
}: CustomDialogActionsProps) => (
    <div className={className}>
      <Button
        size={buttonsSize}
        onClick={handleConfirm}
        type="success"
        disabled={disableSave}
      >
        {confirmText}
      </Button>
    </div>
  );

export default CustomDialogModalAction;
