/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import CookieConsent from 'react-cookie-consent';
import { COOKIE_LAW_COOKIE_NAME } from '@app/lib/cookie-law.constants';

const CookieLaw = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept"
    cookieName={COOKIE_LAW_COOKIE_NAME}
    containerClasses="cookie-wrapper"
    buttonClasses="cookie-btn round-button"
  >
    This website uses cookies for analytics, personalization and advertising. Click{' '}
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.epam.com/cookie-policy"
      className="cookie-link"
    >
      here
    </a>{' '}
    to learn more or change your cookie settings. By continuing to browse, you agree to our use of
    cookies.
  </CookieConsent>
);
export default CookieLaw;
