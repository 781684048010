/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback } from 'react';
import {
  ANALYTICS_GENERAL_ROUTE,
  ANALYTICS_PRODUCT,
  AnalyticsEventCategories,
  AnalyticsEventProperties,
  ANALYTICS_PAGES,
  ANALYTICS_SKIP_AUTO_TRACKING_PAGES,
  ANALYTICS_UNKNOWN_ROUTE,
} from '@services/analytics/analytics.constants';
import { useAnalytics } from '@services/analytics/analytics.hook';
import { AnalyticsEventData } from '@services/analytics/analytics.types';

export interface UseTrackPageOptions<P extends Record<R, N>, R extends keyof P, N extends string> {
  productName?: string;
  eventCategory?: string;
  pages?: P;
  disableAutoPageEvents?: N[];
  generalRoute?: R;
  unknownRoute?: R;
}

export interface TrackPageOptions {
  sendGeneralEvent?: boolean;
}

export const useTrackPage = <P extends Record<R, N>, R extends keyof P, N extends string>(
  settings: UseTrackPageOptions<P, R, N> = {},
) => {
  const analytics = useAnalytics();

  const {
    pages = ANALYTICS_PAGES as unknown as P,
    generalRoute = ANALYTICS_GENERAL_ROUTE as unknown as R,
    unknownRoute = ANALYTICS_UNKNOWN_ROUTE as unknown as R,
    productName = ANALYTICS_PRODUCT,
    eventCategory = AnalyticsEventCategories.NAVIGATION,
    disableAutoPageEvents = ANALYTICS_SKIP_AUTO_TRACKING_PAGES,
  } = settings;

  return useCallback(
    (properties?: AnalyticsEventData, options: TrackPageOptions = {}) => {
      if (!analytics.navigation.isPageChanged()) return;

      const { sendGeneralEvent = true } = options;
      const { currentPage, previousPage } = analytics.navigation;

      const currentPageName =
        pages[currentPage.routeName as keyof typeof pages] ?? pages[unknownRoute];

      if (disableAutoPageEvents.includes(currentPageName) && !properties) return;

      if (sendGeneralEvent) {
        analytics.track(`${productName}_${eventCategory}_${pages[generalRoute]}`, {
          [AnalyticsEventProperties.PageName]: currentPageName,
        });
      }

      if (currentPage.routeName !== generalRoute) {
        const previousPageName = previousPage?.routeName
          ? pages[previousPage.routeName as keyof typeof pages] ?? pages[unknownRoute]
          : undefined;

        analytics.track(`${productName}_${eventCategory}_${currentPageName}`, {
          [AnalyticsEventProperties.PreviousPageName]: previousPageName,
          ...properties,
        });
      }
    },
    [
      analytics,
      disableAutoPageEvents,
      eventCategory,
      generalRoute,
      pages,
      productName,
      unknownRoute,
    ],
  );
};
