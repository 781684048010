/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Attachment12 } from '@core/icons/Attachment12';
import { Download18 } from '@core/icons/Download18';
import { InfoFill18 } from '@core/icons/InfoFill18';
import { Button } from '@perf/ui-components';
import { v4 as uuid } from 'uuid';
import { IAiCommentConfig, IStatementOptionType } from './statements-survey.types';
import StatementDocumentList from '@components/common/statement-document-list/statement-document-list.component';
import { ReviewCatalogAPI } from '@api/services/review-api.resources';
import { StatementDocumentAPI } from '@api/services/statement-api.resources';
import { marginLeft, statementSurveyStyles } from '@app/components/common/statements-survey/statement-survey.style';
import { usePrevious } from '@app/hooks/use-previous.hook';
import COMMENT_ICON from '@app/img/svg/comment.svg';
import { ModalService } from '@app/services/modal.service';
import { NotificationService } from '@app/services/notification.service';
import { ReviewService } from '@app/services/review.service';
import { SpinnerService } from '@app/services/spinner.service';
import { downloadStatementDocument } from '@app/utils/download-statement-document.utils';
import ReviewQuestions from '@components/common/review-questions/review-questions.component';
import DocumentsList from '@components/common/statements-survey/documents-list/documents-list.component';
import SurveyScale from '@components/common/survey-scale/survey-scale.component';
import { ANSWER_TYPES, APP_REVIEW_ROLES, INDICATORS_TYPES, MESSAGE, RESOURCE_TYPES } from '@lib/common.constants';
import { ScaleService } from '@services/scale.service';
import { MainColorPalette } from '@utils/variables';
import { AssessmentStatement, IScoreDetailList, IStatementCallback, IStatementComment, IStatementOption, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { User } from '@root/src/types/user.types';
import { AttachedDocument, StatementDocument } from '@root/src/types/dimension.types';
import StatementsSurveyComment from '@root/src/components/common/statements-survey/statements-survey-comment/statements-survey-comment.components';
import { ASSISTANT_TYPE } from '@components/common/ai-comment/ai-comment.constants';
import CommentScoreSection from '@components/common/comment-score-section/comment-score-section.component';
import { AssessmentState } from '@root/src/store/store.types';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { statementValueMapper } from '@root/src/utils/statement-helpers.utils';
import { setRemovedVoterIdAction } from '@root/src/store/assessment.actions';
import { resetUserScores, sortStatement } from '@components/common/dynamic-survey/dynamic-survey.utils';


const DOCUMENT_ADDED = 'Document successfully added';

interface StatementsSurveyProps {
  anonymous: boolean;
  isEditable: boolean;
  isSurveyType?: boolean;
  onProgressSet: (isProgress: boolean, updatedStatements: AssessmentStatement[], dimensionId: string) => void;
  onVisibleChange?: (statements: AssessmentStatement[]) => AssessmentStatement[];
  parentId?: string;
  previewMode?: boolean;
  roles: APP_REVIEW_ROLES[];
  initialStatements: AssessmentStatement[];
  user: User,
  voter: IVoter | User,
  isDocumentsAvailable: boolean;
  reviewId: string;
  dimensionId: string;
  isFinished: boolean;
  setIsDimensionWasUpdated?: Dispatch<SetStateAction<boolean>>;
}

const StatementsSurvey = ({
  initialStatements,
  dimensionId,
  user,
  voter,
  onProgressSet,
  onVisibleChange,
  isEditable,
  isSurveyType,
  parentId,
  previewMode,
  roles,
  anonymous,
  isDocumentsAvailable,
  reviewId,
  isFinished,
  setIsDimensionWasUpdated,
}: StatementsSurveyProps) => {
  const [statements, setStatements] = useState<AssessmentStatement[]>([]);
  const [shouldLog, setShouldLog] = useState(false);
  const [logMessage, setLogMessage] = useState('');
  const [commentEditModeId, setCommentEditModeId] = useState<string | null>(null);
  const [hasResetEditModeId, setHasResetEditModeId] = useState(true);
  const [aiCommentConfig, setAICommentConfig] = useState<IAiCommentConfig[]>([]);
  const prevCommentEditModeId = usePrevious(commentEditModeId);

  const { removedVoterId } = useAssessmentStore((state) => ({
    removedVoterId: (state as AssessmentState).assessmentState.removedVoterId,
  }));

  useEffect(() => {
    if (removedVoterId) {
      removeUserScoresByVoterId(removedVoterId);
    }
  }, [removedVoterId]);

  useEffect(() => {
    setStatements(Object.values(initialStatements));
  }, [initialStatements]);

  useEffect(() => {
    setHasResetEditModeId(
      !prevCommentEditModeId || !commentEditModeId || prevCommentEditModeId === commentEditModeId,
    );
  }, [commentEditModeId]);

  useEffect(() => {
    if (shouldLog && logMessage) {
      NotificationService.showSuccess(logMessage);
      setShouldLog(false);
    }
  }, [statements]);

  const removeUserScoresByVoterId = (voterId: string) => {
    const updatedStatements = structuredClone(statements);

    updatedStatements.forEach((statement) => {
      if (statement.userScores && statement.userScores[user.id]) {
        statement.userScores[user.id] = statement.userScores[user.id].filter((score) => score.voterId !== voterId);
      }
    });

    setStatements(updatedStatements);
    setRemovedVoterIdAction('');
  };

  const getCommitIndex = (statement: AssessmentStatement) =>
    statement.comments?.findIndex((comment) => comment.voterId === voter.id);

  const hasComment = (statement: AssessmentStatement, index: number) => {
    const commentIndex = getCommitIndex(statement);

    if (statement.commentRequired && (commentIndex === -1 || commentIndex === undefined)) {
      showComment(index, false);
    }

    return !!(statement.comments && statement.comments[commentIndex]);
  };

  const updateStatementState = (targetStatements: AssessmentStatement[]) => {
    let updatedStatements = structuredClone(targetStatements);

    if (onVisibleChange) {
      updatedStatements = onVisibleChange(updatedStatements);
    }

    setStatements(updatedStatements);

    if (!previewMode) {
      onProgressSet(false, updatedStatements, dimensionId);
    }
  };

  const resetVoice = (statementIndex: number, isUserChoicesReset?: boolean) => {
    if (!isEditable) {
      return;
    }

    const updatedStatements = structuredClone(statements);

    const targetStatement = resetUserScores(
      statements[statementIndex],
      voter.id,
      user.id,
      isUserChoicesReset
    );

    if (targetStatement) {
      updatedStatements[statementIndex] = targetStatement;
    }

    if (updatedStatements?.length) {
      updateStatementState(updatedStatements);
    }
  };

  const hasCommentChanges = (statement: AssessmentStatement, currentCommentValue: string) => {
    const commentIndex = getCommitIndex(statement);

    return statement?.comments[commentIndex].text !== currentCommentValue;
  };

  const removeComment = (statementIndex: number, commentId: string) => {
    const clonedStatements = structuredClone(statements);
    const statement = clonedStatements[statementIndex];

    statement.comments = statement.comments?.filter((item) => item.id !== commentId) ?? [];

    if (!commentId) {
      updateCommentEditModeId(null);
      setStatements(clonedStatements);
      return;
    }

    if (anonymous) {
      updateCommentEditModeId(null);
      setStatements(clonedStatements);
      onProgressSet(true, clonedStatements, dimensionId);
    }

    if (!anonymous && commentId) {
      ReviewCatalogAPI.comment(statement.id, {
        text: null,
        id: commentId,
      })
        .then(() => {
          updateCommentEditModeId(null);
          setStatements(clonedStatements);
          onProgressSet(true, clonedStatements, dimensionId);

          setShouldLog(true);
          setLogMessage(MESSAGE.SUCCESS.COMMENT.SAVE);
          setIsDimensionWasUpdated?.(true);
        })
        .catch(ReviewService.showError);
    }
  };

  const saveComment = (statementIndex: number, commentMessage: { text: string, isPublic: boolean }, commentId: string) => {
    const comment = {} as IStatementComment;
    const updatedStatements = structuredClone(statements);
    const statement = updatedStatements[statementIndex];

    if (!hasCommentChanges(statement, commentMessage.text)) {
      updateCommentEditModeId(null);
      return;
    }

    comment.text = commentMessage.text;
    comment.publicComment = commentMessage.isPublic;

    if (anonymous) {
      comment.id = commentId ?? uuid();
    } else {
      comment.id = commentId;
    }

    comment.voterId = voter.id;

    hasResetEditModeId && updateCommentEditModeId(null);

    if (!anonymous) {
      ReviewCatalogAPI.comment(statement.id, comment)
        .then((res) => {
          setStatements((prevStatements) => {
            const newStatements = structuredClone(prevStatements);
            const newStatement = newStatements[statementIndex];
            const newCommentIndex = getCommitIndex(newStatement);

            if (newCommentIndex > -1) {
              newStatement.comments[newCommentIndex] = res;
            } else {
              newStatement.comments.push(res);
            }

            onProgressSet(true, newStatements, dimensionId);
            setShouldLog(true);
            setLogMessage(MESSAGE.SUCCESS.COMMENT.SAVE);

            return newStatements;
          });

        })
        .catch(ReviewService.showError);
    } else {
      comment.statementId = statement.id;
      statement.comments = [comment];

      setStatements(updatedStatements);
      updateCommentEditModeId(null);
      onProgressSet(true, updatedStatements, dimensionId);

      setShouldLog(true);
      setLogMessage(MESSAGE.SUCCESS.COMMENT.SAVE);
    }
  };

  const getUserIdAndVoterId = () => ({ userId: user.id, voterId: voter.id });

  const initializeUserScores = (statement: AssessmentStatement, userId: string) => {
    if (!statement.userScores) {
      statement.userScores = {};
    }

    if (!statement.userScores[userId]) {
      statement.userScores[userId] = [];
    }

    return statement;
  };

  const updateUserScores = (userScores: IScoreDetailList, userId: string, voterId: string, score: INDICATORS_TYPES | null, updatedValue: number, choices?: string[]) => {
    const voterIndex = userScores[userId].findIndex((voterValue) => voterValue.voterId === voterId);

    if (voterIndex > -1) {
      userScores[userId][voterIndex].userScore = score;
      userScores[userId][voterIndex].value = updatedValue || -1;

      if (choices) {
        userScores[userId][voterIndex].userChoices = choices;
      }
    } else {
      userScores[userId].push({
        userScore: score,
        userChoices: choices,
        voterId,
        value: updatedValue || -1,
      } as ScoreDetail);
    }
  };

  const onRemoveAICommentConfig = (id: string) => {
    setAICommentConfig((prevState) => prevState.filter((item) => item.id !== id));
  };

  const onChangeAICommentConfig = (id: string, type: ASSISTANT_TYPE) => {
    setAICommentConfig((prevState) => {
      const hasConfig = prevState.find((item) => item.id === id);

      if (hasConfig) {
        return prevState.map((item) => (item.id === id ? { ...item, type } : item));
      }

      return [
        ...prevState,
        {
          id,
          type,
        },
      ];
    });
  };

  const getScore = (option: IStatementOption, type: ANSWER_TYPES) =>
    !ScaleService.isRadioGroup(type) && !ScaleService.isMetric(type)
      ? option?.label?.toUpperCase()
      : option;

  const getChoices = (choiceVoices: ScoreDetail[], voterIndex: number, type: ANSWER_TYPES) =>
    ScaleService.isOpenEnded(type) ? choiceVoices[voterIndex]?.userChoices : null;

  const handleMultiOrOpenEndedGroup = (statement: AssessmentStatement, option: IStatementOption, userId: string, voterId: string, updatedValue: number) => {
    const { userScores } = statement;
    const choiceVoices = userScores[userId];
    const scaleVoter = choiceVoices.find((voterValue) => voterValue.voterId === voterId) || {} as ScoreDetail;
    const voterIndex = userScores[userId].findIndex((voterValue) => voterValue.voterId === voterId);

    if (option && option.label) {
      const hasChoiceVoices = !!(scaleVoter.userChoices || []).length;

      if (!hasChoiceVoices && ScaleService.isMultiGroup(statement.type)) {
        return;
      }

      const score = getScore(option, statement.type);
      const choices = getChoices(choiceVoices, voterIndex, statement.type);

      updateUserScores(userScores, userId, voterId, score as INDICATORS_TYPES, updatedValue, choices as string[]);
    } else if (ScaleService.isMultiGroup(statement.type)) {
      handleMultiGroup(userScores, option, userId, voterIndex, voterId, updatedValue);
    } else {
      handleOpenEndedGroup(userScores, option as string, userId, voterIndex, voterId, updatedValue);
    }
  };

  const handleMultiGroup = (userScores: IScoreDetailList, option: IStatementOption, userId: string, voterIndex: number, voterId: string, updatedValue: number) => {
    const currentUserChoices = userScores[userId][voterIndex]?.userChoices || [];

    if (currentUserChoices.includes(option.choice as string) && !option.selected) {
      const newUserChoices = currentUserChoices.filter(
        (userChoice) => userChoice !== option.choice,
      );

      if (!newUserChoices.length) {
        updateUserScores(userScores, userId, voterId, null, updatedValue, []);
      } else {
        updateUserScores(userScores, userId, voterId, null, updatedValue, newUserChoices);
      }
    } else {
      updateUserScores(userScores, userId, voterId, null, updatedValue, [...currentUserChoices, option.choice as string]);
    }
  };

  const handleOpenEndedGroup = (userScores: IScoreDetailList, option: string, userId: string, voterIndex: number, voterId: string, updatedValue: number) => {
    const currentUserScores = option?.length ? userScores[userId][voterIndex]?.userScore : null;
    const choices = option?.length ? [option] : [];

    updateUserScores(userScores, userId, voterId, currentUserScores, updatedValue, choices);
  };

  const isNotScoreStatement = (type: ANSWER_TYPES) => !ScaleService.isRadioGroup(type) && !ScaleService.isMetric(type);

  const getScoreValue = (option: IStatementOption, isNotScoreType: boolean) => isNotScoreType ? option?.label?.toUpperCase() : option;

  const handleSingleGroup = (statement: AssessmentStatement, option: IStatementOption, userId: string, voterId: string, updatedValue: number) => {
    const { userScores } = statement;

    const isNotScoreType = isNotScoreStatement(statement.type);
    const score = getScoreValue(option, isNotScoreType);

    updateUserScores(userScores, userId, voterId, score as INDICATORS_TYPES, updatedValue);
  };

  const selectOption = (statementIndex: number, option: IStatementOptionType) => {
    if (!isEditable) {
      return;
    }

    let updatedStatements = structuredClone(statements);
    const { userId, voterId } = getUserIdAndVoterId();
    const statement = initializeUserScores(updatedStatements[statementIndex], userId);
    // implement value mapping
    const updatedValue = statementValueMapper(statement, option) as number;

    if (ScaleService.isMultiGroup(statement.type) || ScaleService.isOpenEnded(statement.type)) {
      handleMultiOrOpenEndedGroup(statement, option as IStatementOption, userId, voterId, updatedValue);
    } else {
      handleSingleGroup(statement, option as IStatementOption, userId, voterId, updatedValue);
    }

    if (onVisibleChange) {
      updatedStatements = onVisibleChange(updatedStatements);
    }

    if (!previewMode) {
      onProgressSet(false, updatedStatements, dimensionId);
    }
    setStatements(updatedStatements);
  };

  const showComment = (statementIndex: number, isUpdatedEditId: boolean) => {
    if (!isEditable) {
      return;
    }
    const updatedStatements = structuredClone(statements);
    const statement = updatedStatements[statementIndex];

    if (!statement.comments) {
      statement.comments = [];
    }

    const commentIndex = getCommitIndex(statement);

    if (commentIndex === -1) {
      statement.comments.push({
        text: '',
        publicComment: true,
        voterId: voter.id,
      } as IStatementComment);
    }
    if (isUpdatedEditId) {
      updateCommentEditModeId(statement.comments[getCommitIndex(statement)].id);
    }

    setStatements(updatedStatements);
  };

  const getStatementComment = (statement: AssessmentStatement) => {
    const commentIndex = getCommitIndex(statement);

    return statement.comments?.[commentIndex];
  };

  const getVoterDocuments = (statement: AssessmentStatement) =>
    statement.voterDocuments?.filter((doc) => (doc.voterId ? doc.voterId === voter.id : true));

  const showStatement = (statement: AssessmentStatement) =>
    (isSurveyType && statement.visible && parentId === statement.parent.id) || !isSurveyType;

  const updateCommentEditModeId = (value: string | null) => {
    setCommentEditModeId(value);
  };

  const attachDocuments = (statementId: string, statementIndex: number, files: AttachedDocument[], callbacks: IStatementCallback) => {
    const updatedStatements = structuredClone(statements);
    const targetStatement = updatedStatements[statementIndex];
    setShouldLog(true);
    setLogMessage(DOCUMENT_ADDED);

    SpinnerService.showSpinner();
    Promise.all(
      files.map((document) =>
        StatementDocumentAPI.upload({
          statementId,
          voterId: voter.id,
          document: document.file,
          comment: document.comment,
          anonymous,
          reviewId,
        }),
      ),
    )
      .then((res) => {
        targetStatement.voterDocuments.push(...res);

        setStatements(updatedStatements);
        onProgressSet(true, updatedStatements, dimensionId);
        callbacks.success();
      })
      .catch((error) => {
        ReviewService.showError(error);
        callbacks.failed();
      })
      .finally(() => {
        SpinnerService.hideSpinner();
      });
  };

  const openDocumentModal = (statementId: string, statementIndex: number) => {
    ModalService.openAddDocumentModal({
      onAccept: (files: AttachedDocument[], callbacks: IStatementCallback) =>
        attachDocuments(statementId, statementIndex, files, callbacks),
    });
  };

  const downloadDocument = (document: StatementDocument, resource: string) => {
    if (previewMode) {
      return;
    }
    downloadStatementDocument(document, resource, anonymous, voter, reviewId);
  };

  const deleteDocument = (documentId: string, statementIndex: number) => {
    SpinnerService.showSpinner();
    (anonymous
      ? StatementDocumentAPI.deleteAnonymous(RESOURCE_TYPES.VOTER, documentId, voter.id, reviewId)
      : StatementDocumentAPI.delete(RESOURCE_TYPES.VOTER, documentId)
    )
      .then(() => {
        const updatedStatements = structuredClone(statements);
        const statement = updatedStatements[statementIndex];

        statement.voterDocuments =
          statement.voterDocuments?.filter((item) => item.id !== documentId) ?? [];

        setStatements(updatedStatements);
        onProgressSet(true, updatedStatements, dimensionId);
      })
      .catch(ReviewService.showError)
      .finally(SpinnerService.hideSpinner);
  };

  const editProperties = (filename: string, comment: string, documentId: string, statementIndex: number) => {
    SpinnerService.showSpinner();
    (anonymous
      ? StatementDocumentAPI.editAnonymous(
        RESOURCE_TYPES.VOTER,
        documentId,
        filename,
        comment,
        voter.id,
        reviewId,
      )
      : StatementDocumentAPI.edit(RESOURCE_TYPES.VOTER, documentId, filename, comment)
    )
      .then(() => {
        const updatedStatements = structuredClone(statements);
        const targetStatement = updatedStatements[statementIndex];
        const documentIndex = targetStatement.voterDocuments.findIndex(
          (item) => item.id === documentId,
        );

        targetStatement.voterDocuments[documentIndex] = {
          ...targetStatement.voterDocuments[documentIndex],
          name: filename,
          comment,
        };

        setStatements(updatedStatements);
      })
      .catch(ReviewService.showError)
      .finally(SpinnerService.hideSpinner);
  };

  const getDocumentsOptions = (document: StatementDocument, statementIndex: number) => {
    if (!isEditable) {
      return [];
    }
    return [
      {
        label: 'Download',
        icon: <Download18 color={MainColorPalette.Grey[500]} />,
        action: () => downloadDocument(document, RESOURCE_TYPES.VOTER),
      },
      {
        label: 'Properties',
        icon: <InfoFill18 color={MainColorPalette.Grey[500]} />,
        action: () =>
          ModalService.openEditDocumentModal({
            document,
            onAccept: (name: string, comment: string) => editProperties(name, comment, document.id, statementIndex),
          }),
      },
    ];
  };

  const getAddDocumentBtnTemplate = (statement: AssessmentStatement, index: number) =>
    <Button
      disabled={!isEditable}
      onClick={() => openDocumentModal(statement.id, index)}
      size="small"
      type="secondary"
      variant="text"
      withLeftIcon
      css={statementSurveyStyles.addDocumentBtn}
    >
      <Attachment12 css={marginLeft} />
      Add Document
    </Button>

  return (
    <section>
      {statements &&
        sortStatement(statements).map((statement: AssessmentStatement, index: number) => {
          if (!showStatement(statement)) {
            return null;
          }

          const statementComment = getStatementComment(statement);

          return (
            <div css={statementSurveyStyles.statementSurvey} key={index}>
              <span css={statementSurveyStyles.statementSurveyName}>{statement.description}</span>

              <div css={statementSurveyStyles.supportingQuestionBlock}>
                <ReviewQuestions supportingQuestions={statement.supportingQuestions} />
                {!!statement.statementDocuments?.length && (
                  <div css={statementSurveyStyles.statementDocument}>
                    <StatementDocumentList
                      statementDocuments={statement.statementDocuments}
                      isDownloadAvailable={!isFinished}
                      downloadDocument={(document) =>
                        downloadDocument(document, RESOURCE_TYPES.REVIEW_STATEMENT)
                      }
                    />
                  </div>
                )}
              </div>

              <SurveyScale
                choices={statement.indicatorChoices}
                indicators={statement.indicatorInfo}
                isEditable={isEditable}
                onReset={(isUserChoicesReset?: boolean) => resetVoice(index, isUserChoicesReset)}
                onSelect={(option: IStatementOptionType) => selectOption(index, option)}
                type={statement.type}
                user={user}
                voices={statement.userScores}
                voter={voter}
                unitOfMeasure={statement.unitOfMeasure as number}
                roles={roles}
                rated={statement.rated}
                previewMode={previewMode as boolean}
                isFinished={isFinished}
                isSurveyType={!!isSurveyType}
              />

              {!previewMode && (
                <div css={statementSurveyStyles.statementSurveyCommentBlock}>
                  {(hasComment(statement, index)) && (
                    <StatementsSurveyComment
                      aiCommentConfig={aiCommentConfig}
                      commentEditModeId={commentEditModeId}
                      index={index}
                      isFinished={isFinished}
                      onChangeAICommentConfig={onChangeAICommentConfig}
                      onRemoveAICommentConfig={onRemoveAICommentConfig}
                      removeComment={removeComment}
                      saveComment={saveComment}
                      statementComment={statementComment}
                      updateCommentEditModeId={updateCommentEditModeId}
                      isCommentRequired={statement.commentRequired}
                    />
                  )}

                  {isDocumentsAvailable && !!getVoterDocuments(statement)?.length &&
                    <div css={!!getVoterDocuments(statement)?.length && statementSurveyStyles.documentLable}>
                      <div css={statementSurveyStyles.documentTitle}>
                        <div css={statementSurveyStyles.documentLable}>Document</div>
                        {getAddDocumentBtnTemplate(statement, index)}
                      </div>
                      <DocumentsList
                        documents={getVoterDocuments(statement)}
                        options={(doc: StatementDocument) => getDocumentsOptions(doc, index)}
                        isFinished={isFinished}
                        onRemove={(id: string) =>
                          ModalService.openAcceptModal({
                            title: 'Deleting a document',
                            description:
                              'Are you sure you want to delete the document? The document will be permanently deleted.',
                            onAccept: () => deleteDocument(id, index),
                          })}
                      />
                    </div>
                  }
                  <CommentScoreSection statement={statement} user={user} />

                  <div css={statementSurveyStyles.statementSurveyButtons}>
                    {!hasComment(statement, index) && (
                      <Button
                        disabled={!isEditable}
                        onClick={() => showComment(index, true)}
                        size="small"
                        type="secondary"
                        variant="text"
                        withLeftIcon
                        withRightIcon
                      >
                        <img
                          alt="add-comment"
                          css={statementSurveyStyles.statementsSurveyButtonsIcon}
                          src={COMMENT_ICON as any}
                        />
                        Add Comment
                      </Button>
                    )}

                    {isDocumentsAvailable && !getVoterDocuments(statement)?.length &&
                      getAddDocumentBtnTemplate(statement, index)
                    }
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </section>
  );
};

export default StatementsSurvey;
