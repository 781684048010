/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { updateDimension } from '@api/query.api';
import { CatalogExportAPI } from '@api/services/catalog-api.resources';
import { addRequestStatus, removeRequestStatus } from '@root/src/store/global.actions';
import { DimensionAPI, DimensionHistoryAPI } from '@api/services/dimension-api.resources';
import { MetaCatalogDimensionAPI } from '@api/services/meta-catalog-api.resources';
import { StatementAPI, StatementHistoryAPI } from '@api/services/statement-api.resources';
import { CatalogActions, useCatalogStore } from '@root/src/store/use-catalog.store';
import { useTemplateStore } from '@app/store/use.template.store';
import {
  setDefaultIndicatorInfo,
  deepDimensionReplace,
  deepStatementReplace,
  getDimensionById,
  getDimensionSelect,
  getStatementSelect,
} from '@app/utils/dimensions.utils';
import {
  MESSAGE,
  DIMENSION_ACTION,
  CATALOG_LIST_TYPE,
  RESOURCE_TYPES,
  DEFAULT_INDICATORS,
  SCALE_TYPE,
  FILE_TYPE,
} from '@lib/common.constants';
import { BASE_CATALOG_VIEW, BASE_CATALOG_VIEW_TYPES } from '@lib/event.constants';
import { API_REQUEST_KEY } from '@lib/requests.constants';
import { CATALOG_KEYS } from '@root/src/hooks/use-catalog-data.hook';
import { AxiosService } from '@services/axios.service';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { SupportService } from '@services/support.service';
import {
  findAndUpdateDimensionById,
  findDimensionById,
  updateDimensions,
  updateStatementByIdObject,
} from '@utils/review-helpers.utils';
import {
  CATALOG_STATE_TYPES,
  Dimension,
  DimensionHistoryItem,
  DimensionList,
  IndicatorInfo,
  Statement,
  StatementHistoryItem,
  StatementList,
} from '@root/src/types/dimension.types';
import { User } from '@app/types/user.types';
import { CatalogStateInterface, CatalogStateSorting, SearchParams, TemplateState } from '@app/store/store.types';

// Dimensions flow

export const setCatalogState = (updatedCatalogState: Partial<CatalogStateInterface>, action?: CatalogActions) =>
  useCatalogStore.setState((state) => ({
    catalogState: {
      ...state.catalogState,
      ...updatedCatalogState,
    },
  }), false, action);

export const setDimensionState = (dimensionState: Dimension) =>
  useCatalogStore.setState(() => ({ dimensionState }), false, CatalogActions.setDimensionState);

export const updateDimensionsForDimensionState = (dimensions: DimensionList) =>
  useCatalogStore.setState((state) => ({
    dimensionState: {
      ...state.dimensionState,
      dimensions,
    },
  }), false, CatalogActions.updateDimensionState);

export const setSelectedDimensionId = (dimensionId: string) =>
  useCatalogStore.setState((state) => ({
    catalogState: {
      ...state.catalogState,
      selectedDimensionId: dimensionId,
    },
  }), false, CatalogActions.setSelectedDimensionId);

export const setActiveDimensionId = (dimensionId: string) =>
  useCatalogStore.setState((state) => ({
    catalogState: {
      ...state.catalogState,
      activeDimensionId: dimensionId,
    },
  }), false, CatalogActions.setActiveDimensionId);

export const setNewDimension = (dimension: Dimension) =>
  useCatalogStore.setState((state) => {
    const dimensionsCopy = structuredClone(state.catalogState.dimensions);

    return {
      catalogState: {
        ...state.catalogState,
        dimensions: {
          ...dimensionsCopy,
          [dimension.id]: dimension,
        },
      },
    };
  }, false, CatalogActions.createNewDimension);

export const setTopLevelModerators = (moderators: User[]) =>
  useCatalogStore.setState((state) => ({
    catalogState: {
      ...state.catalogState,
      topLevelModerators: moderators,
    },
  }), false, CatalogActions.setTopLevelModerators);

export const setSelectedDimensionData = (dimensionState: Dimension) =>
  useCatalogStore.setState((state) => {
    const dimensionsCopy = structuredClone(state.catalogState.dimensions);
    const { dimensions } = findAndUpdateDimensionById(
      { dimensions: dimensionsCopy as DimensionList },
      dimensionState,
      dimensionState.id,
    ) as Dimension;

    return {
      catalogState: {
        ...state.catalogState,
        dimensions,
      },
    };
  }, false, CatalogActions.updateCatalogState);

export const setDimensionsData = (updatedDimensions: DimensionList) =>
  useCatalogStore.setState((state) => {
    const dimensionsCopy = structuredClone(state.catalogState.dimensions);
    const dimensions = updateDimensions(dimensionsCopy as DimensionList, updatedDimensions);

    return {
      catalogState: {
        ...state.catalogState,
        dimensions,
      },
    };
  }, false, CatalogActions.setDimensionsData);

export const onDimensionEdit = async (
  dimension: Dimension,
  baseView: BASE_CATALOG_VIEW_TYPES,
  isHandleRefetch: boolean,
  queryClient: QueryClient,
) => {
  try {
    await updateDimension(dimension)
      .then(() => {
        if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
          useCatalogStore.setState((state) => ({
            catalogState: {
              ...state.catalogState,
              dimensions: deepDimensionReplace(
                state.catalogState.dimensions,
                dimension.id,
                dimension,
              ) as DimensionList,
            },
          }), false, CatalogActions.onDimensionEdit);
          setSelectedDimensionData(dimension);

          if (isHandleRefetch) {
            queryClient.invalidateQueries({ queryKey: [CATALOG_KEYS.fetchAllCatalog] });
          }
        } else {
          const dimensionState = useCatalogStore.getState().dimensionState;
          const { dimensions } = findAndUpdateDimensionById(
            { dimensions: dimensionState.dimensions },
            dimension,
            dimension.id,
          ) as Dimension;
          setDimensionState({
            ...dimensionState,
            dimensions,
          });
        }
      })
      .finally(() => removeRequestStatus([API_REQUEST_KEY.onDimensionEdit]));
  } catch (error) {
    NotificationService.showError(error);
    removeRequestStatus([API_REQUEST_KEY.onDimensionEdit]);
  }
};

export const onDimensionClone = async (dimension: Dimension, action: string) => {
  try {
    const catalogState = useCatalogStore.getState().catalogState;

    ModalService.openAcceptModal({
      title: `${action} Dimension`,
      description: `Are you sure that you want to ${action} dimension to current tenant?`,
      onAccept: () => {
        MetaCatalogDimensionAPI.clone(
          catalogState.tenantId as string,
          AxiosService.getTenantId(),
          dimension.id,
        )
          .then((resDimension: Dimension) => {
            NotificationService.showSuccess(MESSAGE.SUCCESS.DIMENSION_COPY);
            if (action === DIMENSION_ACTION.DUPLICATE) {
              const updatedDimensions = {
                ...catalogState.dimensions,
                [resDimension.id]: resDimension,
              };

              useCatalogStore.setState((state) => ({
                catalogState: {
                  ...state.catalogState,
                  dimensions: updatedDimensions,
                },
              }), false, CatalogActions.onDimensionClone);
            }
          })
          .catch((e: AxiosError) => NotificationService.showError(e));
      },
    });
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onDimensionRemove = async (
  dimensionId: string,
  baseView: BASE_CATALOG_VIEW_TYPES,
  callback: () => void,
  queryClient: QueryClient,
  ignoreInit = false,
) => {
  try {
    ModalService.openAcceptModal({
      title: 'Delete Dimension',
      description: 'Are you sure that you want to delete dimension?',
      onAccept: () => {
        DimensionAPI.remove(dimensionId)
          .then(() => {
            callback && callback();

            if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
              useCatalogStore.setState((state) => ({
                catalogState: {
                  ...state.catalogState,
                  dimensions: deepDimensionReplace(
                    structuredClone(state.catalogState.dimensions) as DimensionList,
                    dimensionId,
                    null,
                  ) as DimensionList,
                },
              }), false, CatalogActions.onDimensionRemove);
            } else if (BASE_CATALOG_VIEW.CATALOG_VIEW !== baseView && !ignoreInit) {
              const dimensionState = useCatalogStore.getState().dimensionState;

              queryClient.invalidateQueries({ queryKey: [CATALOG_KEYS.fetchAllCatalog] });
              loadDimensionData(dimensionState.id, baseView);
            }
          })
          .catch(NotificationService.showError);
      },
    });
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onDimensionHistory = async (
  dimensionId: string,
  callback: (data: DimensionHistoryItem[]) => void,
) => {
  try {
    await DimensionHistoryAPI.get(dimensionId).then(
      (data) => data && callback(data as DimensionHistoryItem[]),
    );
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onDimensionLock = async (dimensionId: string, baseView: BASE_CATALOG_VIEW_TYPES) => {
  try {
    const isCatalogView = BASE_CATALOG_VIEW.CATALOG_VIEW === baseView;
    const { dimensions } = useCatalogStore.getState().catalogState;
    const dimensionState = useCatalogStore.getState().dimensionState;

    const getTargetDimension = () => {
      if (isCatalogView) {
        return dimensions[dimensionId] || findDimensionById({ dimensions }, dimensionId);
      }
      return dimensionState.id === dimensionId
        ? dimensionState
        : findDimensionById({ dimensions: dimensionState.dimensions }, dimensionId);
    };

    const updatedDimension = CatalogService.lockDimension(
      getTargetDimension() as Dimension,
      baseView,
    );

    await DimensionAPI.lock(dimensionId, updatedDimension.locked).then(() => {
      if (isCatalogView) {
        setSelectedDimensionData(updatedDimension);
      } else {
        const dimensionState = useCatalogStore.getState().dimensionState;
        const { dimensions } = findAndUpdateDimensionById(
          { dimensions: dimensionState.dimensions },
          updatedDimension,
          updatedDimension.id,
        ) as Dimension;

        setDimensionState({
          ...dimensionState,
          dimensions,
        });
      }
    });
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onDimensionPublic = async (
  dimensionId: string,
  publicValue: boolean,
  callback: () => void,
) => {
  try {
    await DimensionAPI.public(dimensionId, publicValue).then(() => callback && callback());
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const loadDimensionStatements = async (
  dimensionId: string,
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  try {
    const { dimensions, tenantId, activeDimensionId } = useCatalogStore.getState().catalogState;
    const dimensionState = useCatalogStore.getState().dimensionState;

    const dimension = findDimensionById(
      { dimensions },
      dimensionId || activeDimensionId,
    ) as Dimension;

    await StatementAPI.search(
      dimension.statementIds.map((item) => item.statementId),
      tenantId,
    ).then((statements) => {
      const isCatalogView = baseView === BASE_CATALOG_VIEW.CATALOG_VIEW;
      const targetDimensions = isCatalogView ? dimensions : dimensionState;
      const updatedDimensions = deepDimensionReplace(
        structuredClone(targetDimensions) as DimensionList,
        dimension.id,
        {
          ...dimension,
          statements: (statements as Statement[]).reduce(
            (result, statement) => ({ ...result, [statement.id]: statement }),
            {},
          ),
        },
      );
      isCatalogView
        ? setCatalogState({
            dimensions: updatedDimensions as DimensionList,
          }, CatalogActions.updateDimensions)
        : setDimensionState(updatedDimensions as Dimension);
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateDimensionsOrder = async (
  orderMap: { [key: string]: number },
  callback: () => void,
) => {
  try {
    await DimensionAPI.order({ orderByEntityId: orderMap }).then(() => callback());
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const loadDimensionData = async (dimensionId: string, baseView: BASE_CATALOG_VIEW_TYPES) => {
  addRequestStatus([API_REQUEST_KEY.loadDimensionData]);
  try {
    const { showArchived, tenantId } = useCatalogStore.getState().catalogState;

    await DimensionAPI.get(dimensionId, showArchived, tenantId)
      .then((dimension) => {
        const id = (dimension as Dimension).id;

        if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
          const { dimensions } = useCatalogStore.getState().catalogState;

          setCatalogState({
            dimensions: deepDimensionReplace(
              structuredClone(dimensions) as DimensionList,
              id,
              dimension as Dimension,
            ) as DimensionList,
          }, CatalogActions.updateDimensions);
        } else {
          setDimensionState(dimension as Dimension);

          setSelectedDimensionId(id);
        }
      })
      .finally(() => removeRequestStatus([API_REQUEST_KEY.loadDimensionData]));
  } catch (error) {
    NotificationService.showError(error);
    removeRequestStatus([API_REQUEST_KEY.loadDimensionData]);
  }
};

export const onDimensionStatus = async (
  dimension: Dimension,
  catalogState: CATALOG_STATE_TYPES,
  baseView: BASE_CATALOG_VIEW_TYPES,
  fetchData: () => Promise<void>,
) => {
  try {
    await DimensionAPI.catalogState(dimension.id, catalogState)
      .then(() => {
        fetchData().then(() => {
          if (BASE_CATALOG_VIEW.DIMENSION_VIEW === baseView) {
            const dimensionState = useCatalogStore.getState().dimensionState;
            loadDimensionData(dimensionState.id, baseView);
          }
        });
      })
      .finally(() => removeRequestStatus([API_REQUEST_KEY.onDimensionStatus]));
  } catch (error) {
    NotificationService.showError(error);
    removeRequestStatus([API_REQUEST_KEY.onDimensionStatus]);
  }
};

export const onDimensionSelect = async (
  dimension: Dimension,
  isSelected: boolean,
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  try {
    const catalogState = useCatalogStore.getState().catalogState;
    const storeSelectedDimensions = catalogState.selectedDimensions;
    const storeSelectedStatements = catalogState.selectedStatements;
    const { selectedDimensions, selectedStatements, showEditMode } = getDimensionSelect(
      dimension,
      isSelected,
      catalogState.dimensions as DimensionList,
      storeSelectedDimensions,
      storeSelectedStatements,
      baseView,
    );

    setCatalogState({
      selectedDimensions,
      selectedStatements,
      showEditMode,
    }, CatalogActions.updateSelectedItems);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const addDimension = (
  parentId: string,
  level: number,
  maxOrder: number,
  callback: (data: Dimension) => void,
  baseView: BASE_CATALOG_VIEW_TYPES,
  queryClient: QueryClient,
) => {
  try {
    const { user } = useCatalogStore.getState().catalogState;
    const isCatalogView = BASE_CATALOG_VIEW.CATALOG_VIEW === baseView;
    const dimensionState = useCatalogStore.getState().dimensionState;
    const parentDimension = findDimension(parentId, baseView);
    const parentIndicatorInfo = JSService.isEmptyOrNullObject(
      parentDimension?.indicatorInfo,
    )
      ? structuredClone(DEFAULT_INDICATORS[SCALE_TYPE.RATING.value])
      : parentDimension?.indicatorInfo;

    ModalService.openDimensionModal({
      title: `Add ${CatalogService.getLevelName(level)}`,
      dimension: {
        moderators: isCatalogView ? [{ ...user }] : CatalogService.getModerators(dimensionState, BASE_CATALOG_VIEW_TYPES.CATALOG_VIEW),
        parentIndicatorInfo: parentDimension ? setDefaultIndicatorInfo(parentIndicatorInfo as IndicatorInfo) : null,
      },
      showModerators: true,
      onAccept: (dimension: Partial<Dimension>) => {
        const updatedDimension = CatalogService.setNextOrder(
          dimension,
          maxOrder,
        ) as Partial<Dimension>;

        DimensionAPI.save(updatedDimension, parentId)
          .then((data) => {
            callback && callback(data as Dimension);
            if (!isCatalogView) {
              queryClient.invalidateQueries({ queryKey: [CATALOG_KEYS.fetchAllCatalog] });
              loadDimensionData(parentId, BASE_CATALOG_VIEW_TYPES.DIMENSION_VIEW);
            }
          })
          .catch(NotificationService.showError);
      },
      user,
      isModeratorsDisabled: !isCatalogView,
      baseView,
    });
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const addStatement = (
  parentId: string,
  _level: number,
  maxOrder: number,
  callback: () => void,
  baseView: BASE_CATALOG_VIEW_TYPES,
  queryClient: QueryClient,
) => {
  try {
    const dimensionState = useCatalogStore.getState().dimensionState;
    const parentDimension = getDimensionById(parentId, dimensionState);
    const isCatalogView = BASE_CATALOG_VIEW.CATALOG_VIEW === baseView;
    const statement = isCatalogView
      ? {}
      : {
          parentIndicatorInfo: parentDimension?.indicatorInfo,
          hasParent: parentDimension,
        };

    ModalService.openStatementModal({
      title: `Add ${CATALOG_LIST_TYPE.STATEMENT}`,
      statement,
      onAccept: (currentStatement: Statement, documentIds: string[]) => {
        const updatedStatement = CatalogService.setNextOrder(
          currentStatement,
          maxOrder,
        ) as Partial<Statement>;

        StatementAPI.save(updatedStatement, parentId, documentIds)
          .then(() => {
            callback && callback();
            if (!isCatalogView) {
              queryClient.invalidateQueries({ queryKey: [CATALOG_KEYS.fetchAllCatalog] });
              loadDimensionData(parentId, BASE_CATALOG_VIEW_TYPES.DIMENSION_VIEW);
            }
          })
          .catch(NotificationService.showError);
      },
      resource: RESOURCE_TYPES.CATALOG_STATEMENT,
    });
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const addChild = (
  type: string,
  parentId: string,
  level: number,
  maxOrder: number,
  callback: (dimension?: Dimension) => void,
  baseView: BASE_CATALOG_VIEW_TYPES,
  queryClient: QueryClient,
) => {
  if (type === CATALOG_LIST_TYPE.DIMENSION) {
    addDimension(parentId, level, maxOrder, callback, baseView, queryClient);
  } else {
    addStatement(parentId, level, maxOrder, callback, baseView, queryClient);
  }
};

export const handleStatementSelect = async (
  item: Statement,
  parent: Dimension,
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  try {
    const { selectedDimensions, selectedStatements, dimensions } =
      useCatalogStore.getState().catalogState;
    const dimensionState =
      BASE_CATALOG_VIEW.CATALOG_VIEW === baseView
        ? dimensions
        : useCatalogStore.getState().dimensionState;

    const {
      selectedDimensions: updatedSelectedDimensions,
      selectedStatements: updatedSelectedStatements,
      showEditMode,
    } = getStatementSelect(
      item,
      parent,
      dimensionState as DimensionList | Dimension,
      selectedDimensions,
      selectedStatements,
      baseView,
    );
    setCatalogState({
      selectedDimensions: updatedSelectedDimensions,
      selectedStatements: updatedSelectedStatements,
      showEditMode,
    }, CatalogActions.updateSelectedItems);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onStatementEdit = async (statement: Statement, baseView: BASE_CATALOG_VIEW_TYPES) => {
  try {
    await StatementAPI.update(statement)
      .then(() => {
        if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
          const { dimensions } = useCatalogStore.getState().catalogState;
          setCatalogState({
            dimensions: deepStatementReplace(dimensions, statement.id, statement),
          }, CatalogActions.onStatementEdit);
        } else {
          const dimensionState = useCatalogStore.getState().dimensionState;
          setDimensionState(
            updateStatementByIdObject(
              statement.id,
              structuredClone(dimensionState),
              statement,
            ) as Dimension,
          );
        }
      })
      .catch(NotificationService.showError);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onStatementHistory = async (
  statementId: string,
  callback: (statement: StatementHistoryItem[]) => void,
) => {
  try {
    await StatementHistoryAPI.get(statementId).then((history) =>
      callback(history as StatementHistoryItem[]),
    );
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const updateStatementsOrder = async (
  orderMap: { [key: string]: number },
  callback: () => void,
) => {
  try {
    await StatementAPI.order({ orderByEntityId: orderMap }).then(callback);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onStatementRemove = async (
  statementId: string,
  dimensionId?: string,
  baseView?: BASE_CATALOG_VIEW_TYPES,
) => {
  try {
    await StatementAPI.remove(statementId)
      .then(() => {
        if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
          const { dimensions } = useCatalogStore.getState().catalogState;
          const dimension = findDimensionById({ dimensions }, dimensionId as string);

          setCatalogState({
            dimensions: updateStatementByIdObject(
              statementId,
              structuredClone(dimension) as Dimension,
              null,
            ) as DimensionList,
          }, CatalogActions.onStatementRemove);
        } else {
          const dimensionState = useCatalogStore.getState().dimensionState;
          const updatedData = updateStatementByIdObject(
            statementId,
            structuredClone(dimensionState),
            null,
          );
          setDimensionState(updatedData as Dimension);
        }
      })
      .catch(NotificationService.showError);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const onStatementStatus = async (
  statementId: string,
  catalogState: CATALOG_STATE_TYPES,
  baseView: BASE_CATALOG_VIEW_TYPES,
  _dimensionId: string,
  queryClient: QueryClient,
) => {
  try {
    await StatementAPI.catalogState(statementId, catalogState)
      .then(() => {
        if (BASE_CATALOG_VIEW.DIMENSION_VIEW === baseView) {
          const { id } = useCatalogStore.getState().dimensionState;
          loadDimensionData(id, baseView);
        }
        queryClient.invalidateQueries({ queryKey: [CATALOG_KEYS.fetchAllCatalog] });
      })
      .catch(NotificationService.showError);
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const setUpdatedStatementsByDimensionId = async (
  statements: StatementList,
  dimensionId: string,
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  if (BASE_CATALOG_VIEW.CATALOG_VIEW === baseView) {
    const { dimensions } = useCatalogStore.getState().catalogState;
    const dimension = findDimensionById({ dimensions }, dimensionId) as Dimension;
    const targetDimension = structuredClone(dimension);

    if (targetDimension) {
      setCatalogState({
        dimensions: {
          ...dimensions,
          [targetDimension.id]: {
            ...targetDimension,
            statements,
          },
        },
      }, CatalogActions.updateStatements);
    }
  } else {
    const dimensionState = useCatalogStore.getState().dimensionState;

    if (dimensionState.id === dimensionId) {
      setDimensionState({
        ...dimensionState,
        statements,
      });
    } else {
      const dimension = findDimensionById({ dimensions: dimensionState.dimensions }, dimensionId) as Dimension;
      const targetDimension = structuredClone(dimension);

      targetDimension.statements = statements;

      const { dimensions } = findAndUpdateDimensionById(
        { dimensions: dimensionState.dimensions },
        targetDimension,
        targetDimension.id,
      ) as Dimension;

      setDimensionState({
        ...dimensionState,
        dimensions,
      });
    }
  }
};

// Statements flow

export const handleStatementEdit = async (statement: Statement, callback: () => void) => {
  try {
    await StatementAPI.update(statement).then(() => callback());
  } catch (error) {
    console.error(error);
  }
};

export const handleStatementHistory = async (
  statementId: string,
  callback: (statement: StatementHistoryItem[]) => void,
) => {
  try {
    await StatementHistoryAPI.get(statementId).then((history) =>
      callback(history as StatementHistoryItem[]),
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleStatementState = async (
  statementId: string,
  catalogState: CATALOG_STATE_TYPES,
  callback: () => void,
) => {
  try {
    await StatementAPI.catalogState(statementId, catalogState).then(() => callback());
  } catch (error) {
    console.error(error);
  }
};

export const handleStatementRemove = async (statementId: string, callback: () => void) => {
  try {
    await StatementAPI.remove(statementId).then(() => callback());
  } catch (error) {
    NotificationService.showError(error);
  }
};

export const findDimension = (dimensionId: string, baseView?: BASE_CATALOG_VIEW_TYPES) => {
  const templateState = (useTemplateStore.getState() as TemplateState).templateState;

  if (
    !baseView ||
    (baseView === BASE_CATALOG_VIEW_TYPES.TEMPLATE_VIEW &&
      !JSService.isEmptyOrNullObject(templateState))
  ) {
    return findDimensionById({ dimensions: templateState }, dimensionId);
  }
  const isCatalogView = BASE_CATALOG_VIEW.CATALOG_VIEW === baseView;
  const { dimensions } = useCatalogStore.getState().catalogState;
  const dimensionState = useCatalogStore.getState().dimensionState;
  const storeDimension = isCatalogView ? { dimensions, id: null } : dimensionState;

  return findDimensionById(storeDimension, dimensionId);
};

export const onExportCatalog = async () => {
  addRequestStatus([API_REQUEST_KEY.exportCatalog]);
  const { selectedDimensions, selectedStatements } = useCatalogStore.getState().catalogState;

  CatalogExportAPI.xlsx({
    dimensionIds: selectedDimensions,
    statementIds: selectedStatements,
  })
    .then((res: any) => {
      SupportService.generateFile(res, FILE_TYPE.XLSX, 'Catalog.xlsx');
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.exportCatalog]));
};

export const onDimensionMove = async (
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  const dimensionState = useCatalogStore.getState().dimensionState;

  loadDimensionData(dimensionState.id, baseView);
};

export const setTenantIdAction = (tenantId: string | undefined) => {
  setCatalogState({
    tenantId,
  }, CatalogActions.setTenantId);
};

export const setSortingAction = (sorting: CatalogStateSorting | undefined) => {
  setCatalogState({
    sorting,
  }, CatalogActions.setSorting);
};

export const handleShowArchivedAction = (showArchived: boolean) => {
  setCatalogState({
    showArchived,
  }, CatalogActions.handleShowArchived);
};

export const setSearchParamsAction = (searchParams: SearchParams | undefined) => {
  setCatalogState({
    searchParams,
  }, CatalogActions.setSearchParams);
};

export const setCurrentPageAction = (currentPage: number) => {
  setCatalogState({
    currentPage,
  }, CatalogActions.setCurrentPage);
};

export const setPageCountAction = (pageCount: number) => {
  setCatalogState({
    pageCount,
  }, CatalogActions.setPageCount);
};

export const setGroupingAction = (grouping: string) => {
  setCatalogState({
    grouping,
  }, CatalogActions.setGrouping);
};

export const resetSelectionAction = () => {
  setCatalogState({
    selectedDimensions: [],
    selectedStatements: [],
    showEditMode: false,
  }, CatalogActions.resetSelection);
};

export const updateDimensionsAction = (dimensions?: DimensionList | undefined) => {
  setCatalogState({
    dimensions,
  }, CatalogActions.updateDimensions);
};

export const setUserAction = (user: User | undefined) => {
  setCatalogState({
    user,
  }, CatalogActions.setUser);
};

export const setExpandedViewAction = (expandedView?: string[] | undefined) => {
  setCatalogState({
    expandedView,
  }, CatalogActions.setExpandedView);
};

export const setDimensionTreeDataLoadingAction = (dimensionTreeDataLoading?: string[] | undefined) => {
  setCatalogState({
    dimensionTreeDataLoading,
  }, CatalogActions.setDimensionTreeDataLoading);
};

export const setShowEditModeAction = (showEditMode?: boolean | undefined) => {
  setCatalogState({
    showEditMode,
  }, CatalogActions.setShowEditMode);
};

export const updateSelectedStatementsAction = (selectedStatements?: string[] | undefined) => {
  setCatalogState({
    selectedStatements,
  }, CatalogActions.updateSelectedStatements);
};

export const updateSelectedDimensionsAction = (selectedDimensions?: string[] | undefined) => {
  setCatalogState({
    selectedDimensions,
  }, CatalogActions.updateSelectedDimensions);
};

export const setSelectedDimensionIdAction = (selectedDimensionId: string) => {
  setCatalogState({
    selectedDimensionId,
  }, CatalogActions.setSelectedDimensionId);
};

export const resetSelectedDimensionsAndStatementsAction = () => {
  setCatalogState({
    selectedDimensions: [],
    selectedStatements: [],
    showEditMode: false,
  }, CatalogActions.resetSelectedDimensionsAndStatements);
};
