/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { findDimension } from '@app/store/catalog.actions';
import {
  ANSWER_TYPES,
  CATALOG_STATE,
  DEFAULT_INDICATORS,
} from '@lib/common.constants';
import { BASE_CATALOG_VIEW_TYPES } from '@lib/event.constants';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';
import { ScaleService } from '@services/scale.service';
import { SupportService } from '@services/support.service';
import {
  DimensionList,
  IndicatorInfo,
  Statement,
  Dimension,
  Tag,
} from '@root/src/types/dimension.types';
import { IAdaptTaxonomyTag } from '@components/search/search-taxonomies-tree/search-taxonomies-tree.types';
import { CatalogDimension } from '@app/types/catalog.types';
import { updateOpenedIDsAction } from '@app/store/assessment.actions';
import { updateIDs } from '@components/views/assessment-view/assessment-view.utils';

/**
 * Function can be used to create array
 * with all nested dimensions for provided one.
 *
 * @param dimension catalog dimension object
 * @returns {dimension[]}
 */
export const collectDimensions = (dimension: Dimension) => {
  let dimensions = Object.values(dimension.dimensions ?? {});

  if (dimensions.length > 0) {
    dimensions.forEach((item) => {
      dimensions = [...dimensions, ...collectDimensions(item)];
    });
  }

  return dimensions;
};

export const flatDimensions = (dimensions: DimensionList) => {
  if (JSService.isEmptyOrNullObject(dimensions)) {
    return [];
  }

  const dimensionsData = Object.values(dimensions);

  return dimensionsData.reduce(
    (result, item) => [...(result as Dimension[]), item, ...collectDimensions(item)],
    [] as Dimension[],
  );
};

/**
 * Function can be used to create object
 * with all not archived nested dimensions ids and statements ids for provided one.
 *
 * @param dimension catalog dimension object
 * @returns {{dimensionsIds: string[], statementsIds: FlatArray<string[][], 1>[]}}
 */
export const getNestedItemsIds = (dimension: any) => {
  const allDimensions = [dimension, ...collectDimensions(dimension)];
  const statementsIds = allDimensions
    .map(({ statementIds, statements }) =>
      statementIds.length !== 0
        ? statementIds
            .filter((item: any) => item.catalogState !== CATALOG_STATE.ARCHIVED)
            .map((item: any) => item.statementId)
        : Object.values(statements ?? {})
            .filter((item: any) => item.catalogState !== CATALOG_STATE.ARCHIVED)
            .map((item: any) => item.id),
    )
    .flat();

  return {
    dimensionsIds: allDimensions
      .filter((item) => item.catalogState !== CATALOG_STATE.ARCHIVED)
      .map(({ id }) => id),
    statementsIds,
  };
};

/**
 * Function can be used to get root dimension from the catalog
 *
 * @param item statement or dimension from the catalog tree
 * @returns {{id: string}} dimension
 */
export const getRootParent = (item: any, baseValue: BASE_CATALOG_VIEW_TYPES): any => {
  if (!item.parentId) {
    return item;
  }

  const parentItem = findDimension(item.parentId, baseValue);

  return getRootParent(parentItem, baseValue);
};

/**
 * Function can be used to get array with all parents for provided item
 *
 * @param item statement or dimension from the catalog tree
 * @returns {dimension[]}
 */
export const getParents = (item: any, baseView: BASE_CATALOG_VIEW_TYPES): any => {
  if (item.parentId) {
    const parentItem = findDimension(item.parentId, baseView);
    const topLevelParents = getParents(parentItem, baseView);

    return [item, ...topLevelParents];
  }
  return [item];
};

/**
 * Function can be used to update dimension by id in the dimensions tree
 */
export const deepDimensionReplace = (
  target: DimensionList | Dimension[] | Dimension,
  keyName: string,
  dimension: Dimension | null,
) => {
  for (const key in target) {
    if (key === keyName) {
      if (dimension) {
        target[key] = dimension;
      } else {
        delete target[key];
      }
    } else if (Array.isArray(target[key])) {
      target[key].forEach((member: Dimension) => deepDimensionReplace(member, keyName, dimension));
    } else if (typeof target[key] === 'object') {
      deepDimensionReplace(target[key], keyName, dimension);
    }
  }
  return target;
};

/**
 * Function can be used to update dimension by id in the dimensions array
 */
export const deepArrayDimensionReplace = (target: CatalogDimension[] | CatalogDimension, id: string, dimension: CatalogDimension): CatalogDimension[] | CatalogDimension => {
  if (Array.isArray(target) && target.length) {
    return target.map((dim: any) =>
      dim.id === id
        ? dimension
        : {
            ...dim,
            dimensions: Array.isArray(dim.dimensions) ? dim.dimensions?.map((item: any) =>
              deepArrayDimensionReplace(item, id, dimension),
            ) : [],
          },
    );
  }
  if (!Array.isArray(target) && target.id !== id && target.dimensions.length) {
    return {
      ...target,
      dimensions: Array.isArray(target.dimensions) ? target.dimensions?.map((item: any) =>
        deepArrayDimensionReplace(item, id, dimension) as CatalogDimension,
      ) : [],
    };
  }
  return (target as CatalogDimension).id === id ? dimension : target;
};

/**
 * Function can be used to update statement by id in the dimensions tree
 */
export const deepStatementReplace = (
  dimensions: DimensionList,
  statementId: string,
  updatedStatement: Statement,
) => {
  const updatedDimensions = { ...dimensions };

  for (const key in dimensions) {
    const dimension = dimensions[key];
    let isUpdated = false;

    if (dimension.statements) {
      const statements = { ...dimension.statements };

      for (const statementKey in dimension.statements) {
        if (dimension.statements[statementKey].id === statementId) {
          statements[statementKey] = updatedStatement;
          isUpdated = true;
        }
      }

      updatedDimensions[key] = {
        ...dimension,
        statements,
      };
    }
    if (dimension.dimensions && !isUpdated) {
      updatedDimensions[key] = {
        ...dimension,
        dimensions: deepStatementReplace(dimension.dimensions, statementId, updatedStatement),
      };
    }
  }

  return updatedDimensions;
};

/**
 * Function can be used to adapt category tags for Tree component
 *
 * @param categoryTags category tags object
 * @returns {tags[]}
 */
export const adaptTags = (categoryTags: Tag[]): IAdaptTaxonomyTag[] =>
  categoryTags.map(({ id, name, tags, statementCount }) => ({
    key: id,
    title: Number.isInteger(statementCount) ? `${name} (${statementCount})` : `${name}`,
    children: adaptTags(tags),
  }));

/**
 * Determines whether the dimension is a root dimension
 *
 * @param dimension dimension to check
 * @returns {Boolean}
 */
export const isFirstLevelDimension = (dimension: any) => !SupportService.hasParent(dimension);

/**
 * Determines whether the dimension is a dimension of second level of nesting
 * (sub-dimension)
 *
 * @param dimension dimension to check
 * @returns {Boolean}
 */
export const isSecondLevelDimension = (dimension: any, baseView: BASE_CATALOG_VIEW_TYPES) => {
  const parentDimension = findDimension(dimension.parentId, baseView);

  return SupportService.hasParent(dimension) && !SupportService.hasParent(parentDimension);
};

/**
 * Determines whether the dimension is a dimension of third level of nesting
 * (sub-sub-dimension).
 *
 * @param dimension dimension to check
 * @returns {Boolean}
 */
export const isThirdLevelDimension = (dimension: any, baseView: BASE_CATALOG_VIEW_TYPES) => {
  const parentDimension = findDimension(dimension.parentId, baseView) as Dimension;
  const grandParent = parentDimension
    ? findDimension(parentDimension.parentId as string, baseView)
    : null;

  return (
    SupportService.hasParent(dimension) &&
    SupportService.hasParent(parentDimension) &&
    !SupportService.hasParent(grandParent)
  );
};

/**
 * Get level of nesting of dimension (root, sub or sub-sub)
 *
 *
 * @param dimension dimension to check
 * @returns {{number: number, name: string}}
 */
export const getDimensionLevel = <T>(dimension: T, baseView: BASE_CATALOG_VIEW_TYPES) => {
  let level;
  if (isFirstLevelDimension(dimension)) {
    level = 1;
  } else if (isSecondLevelDimension(dimension, baseView)) {
    level = 2;
  } else if (isThirdLevelDimension(dimension, baseView)) {
    level = 3;
  }
  return (
    level && {
      number: level,
      name: CatalogService.getLevelName(level),
    }
  );
};

/** Get new state of selection for edit mode
 *
 *
 * @param selectedDimension selected dimension object
 * @param isSelected boolean
 * @param dimensionState dimension on which page (if any) is selecting items
 * @param selectionState
 * @returns {{selectedDimensions: string[], selectedStatements: string[], showEditMode: boolean}}
 */
export const getDimensionSelect = (
  selectedDimension: Dimension,
  isSelected: boolean,
  dimensionState: DimensionList,
  selectedDimensions: string[],
  selectedStatements: string[],
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  const parents = selectedDimension.parentId
    ? getParents(findDimension(selectedDimension.parentId, baseView), baseView)
    : [];
  const parentsIds = parents.map((parent: Dimension) => parent.id);
  const { dimensionsIds, statementsIds } = getNestedItemsIds(selectedDimension);

  let updatedSelectedDimensions = [...selectedDimensions];
  let updatedSelectedStatements = [...selectedStatements];

  if (isSelected) {
    updatedSelectedDimensions = [
      ...updatedSelectedDimensions,
      ...dimensionsIds.concat(parentsIds).filter((id) => !updatedSelectedDimensions.includes(id)),
    ];

    updatedSelectedStatements = [
      ...updatedSelectedStatements,
      ...statementsIds.filter((id) => !updatedSelectedStatements.includes(id)),
    ];
  } else {
    const rootParent =
      BASE_CATALOG_VIEW_TYPES.CATALOG_VIEW === baseView
        ? dimensionState[getRootParent(selectedDimension, baseView).id]
        : dimensionState;
    const allDimensions = [rootParent, ...collectDimensions(rootParent as Dimension)];

    updatedSelectedStatements = updatedSelectedStatements.filter(
      (id) => !statementsIds.includes(id),
    );

    updatedSelectedDimensions = updatedSelectedDimensions.filter(
      (dataId) => !dimensionsIds.includes(dataId),
    );
    updatedSelectedDimensions = updatedSelectedDimensions.filter(
      (dataId) =>
        !parentsIds
          .filter((parentId: string) => {
            const parent = allDimensions.find(({ id }: any) => parentId === id) as Dimension;

            return (
              Object.keys(parent?.dimensions ?? {}).every(
                (id) => !updatedSelectedDimensions.includes(id),
              ) &&
              Object.keys(parent?.statements ?? {}).every(
                (id) => !updatedSelectedStatements.includes(id),
              )
            );
          })
          .includes(dataId),
    );
  }

  return {
    selectedDimensions: updatedSelectedDimensions,
    selectedStatements: updatedSelectedStatements,
    showEditMode: !!updatedSelectedDimensions.length,
  };
};

/** Get new state of selection for edit mode
 *
 *
 * @param item selected dimension object
 * @param parent dimension object
 * @param dimensionState dimension on which page (if any) is selecting items
 * @param selectionState
 * @returns {{selectedDimensions: string[], selectedStatements: string[], showEditMode: bool}}
 */
export const getStatementSelect = (
  item: Statement,
  parent: Dimension,
  dimensionState: Dimension | DimensionList,
  selectedDimensions: string[],
  selectedStatements: string[],
  baseView: BASE_CATALOG_VIEW_TYPES,
) => {
  const parents = getParents(parent, baseView);

  let updatedSelectedStatements = [...selectedStatements];
  let updatedSelectedDimensions = [...selectedDimensions];

  if (!updatedSelectedStatements.includes(item.id)) {
    updatedSelectedStatements.push(item.id);

    updatedSelectedDimensions = [
      ...updatedSelectedDimensions,
      ...parents
        .map((dimension: any) => dimension.id)
        .filter((parentId: string) => !updatedSelectedDimensions.includes(parentId)),
    ];
  } else {
    const rootParent =
      BASE_CATALOG_VIEW_TYPES.CATALOG_VIEW === baseView
        ? dimensionState[getRootParent(parent, baseView).id]
        : dimensionState;
    const nestedDimensions = [rootParent, ...collectDimensions(rootParent)];

    updatedSelectedStatements = updatedSelectedStatements.filter((itemId) => itemId !== item.id);

    parents.forEach((data: any) => {
      const statementsSelectionsEmpty = Object.keys(data.statements).every(
        (id) => !updatedSelectedStatements.includes(id),
      );
      const dimensionsSelectionsEmpty = Object.keys(
        nestedDimensions.find(({ id }) => id === data.id)?.dimensions || {},
      ).every((id) => !updatedSelectedDimensions.includes(id));

      if (statementsSelectionsEmpty && dimensionsSelectionsEmpty) {
        updatedSelectedDimensions = updatedSelectedDimensions.filter(
          (dataId) => dataId !== data.id,
        );
      }
    });
  }

  return {
    selectedDimensions: updatedSelectedDimensions,
    selectedStatements: updatedSelectedStatements,
    showEditMode: !!updatedSelectedDimensions.length,
  };
};

export const isTypeHasRating = (type: ANSWER_TYPES) =>
  ScaleService.isRating(type) || ScaleService.isMultiGroup(type) || ScaleService.isOpenEnded(type);

export const setDefaultIndicatorInfo = (
  indicatorInfo: IndicatorInfo,
  type = ANSWER_TYPES.RATING,
) => {
  if (!indicatorInfo) {
    return DEFAULT_INDICATORS[type];
  }

  const updatedIndicatorInfo = structuredClone(indicatorInfo);

  Object.keys(updatedIndicatorInfo).forEach((indicatorId) => {
    if (JSService.isEmptyOrNullObject(updatedIndicatorInfo[indicatorId])) {
      updatedIndicatorInfo[indicatorId] = DEFAULT_INDICATORS[type][indicatorId];
    } else {
      if (!updatedIndicatorInfo[indicatorId]?.name) {
        updatedIndicatorInfo[indicatorId].name = DEFAULT_INDICATORS[type][indicatorId].name;
      }
      if (!updatedIndicatorInfo[indicatorId]?.description) {
        updatedIndicatorInfo[indicatorId].description =
          DEFAULT_INDICATORS[type][indicatorId].description;
      }
    }
  });

  return {
    ...DEFAULT_INDICATORS[type],
    ...updatedIndicatorInfo,
  };
};

export const getStatementDocumentIds = (dimensions: any): any =>
  Object.values(dimensions).reduce((allDocumentIds, dimension) => {
    let newDocumentIds = allDocumentIds;

    if (!JSService.isEmptyOrNullObject((dimension as any).dimensions)) {
      newDocumentIds = [
        ...(newDocumentIds as any),
        ...(getStatementDocumentIds((dimension as any).dimensions) as any),
      ];
    }

    return [
      ...(newDocumentIds as any),
      ...((Object.values((dimension as any).statements)?.reduce(
        (statementDocumentIds, statement) => [
          ...(statementDocumentIds as any),
          ...((statement as any).statementDocuments
            ? (statement as any).statementDocuments.map(({ id }: any) => id)
            : []),
        ],
        [],
      ) || []) as any),
    ];
  }, []);

export const getDimensionById = (id: string, targetDimensionState: Dimension): Dimension | null => {
  if (targetDimensionState.id === id) {
    return targetDimensionState;
  }

  if (Object.keys(targetDimensionState.dimensions).length) {
    return Object.keys(targetDimensionState.dimensions)
      .map((dimensionId) => {
        if (dimensionId === id) {
          return targetDimensionState.dimensions[dimensionId];
        }
        return getDimensionById(id, targetDimensionState.dimensions[dimensionId]);
      })
      .filter(Boolean)[0];
  }
  return null;
};

export const objectFromDataArray = (dataArray: any) => {
  if (!dataArray || !Array.isArray(dataArray)) {
    return {};
  }
  return dataArray.reduce(
    (accumulatedObject, currentItem) => ({
      ...accumulatedObject,
      [currentItem.id]: currentItem,
    }),
    {},
  );
};

export const setOpenedDimensionsIDs = (
  dimensions: CatalogDimension[],
  isOpened: boolean,
): CatalogDimension[] => {
  let updatedIDs = [] as string[];

  const setNewDimensionsToggled = (targetDimensions: CatalogDimension[]) => {
    targetDimensions.forEach((dimension) => {
      updatedIDs = updateIDs(updatedIDs, dimension, !isOpened);

      if (dimension.dimensions?.length) {
        setNewDimensionsToggled(dimension.dimensions);
      }
    });
  };

  setNewDimensionsToggled(dimensions);

  updateOpenedIDsAction(updatedIDs);

  return dimensions;
};

export const convertDimensionObjectToArray = (initialData: CatalogDimension[]) => {
  let dimensionsArray = structuredClone(initialData);
  if (JSService.isObject(dimensionsArray)) {
    dimensionsArray = Object.keys(dimensionsArray).map((key) => {
      const dimensionEl = { ...dimensionsArray[key] };
      if (JSService.isObject(dimensionEl.statements)) {
        dimensionEl.statements = Object.keys(dimensionEl.statements).map(
          (statement) => dimensionEl.statements[statement],
        );
      }
      return dimensionEl;
    });
  }

  return dimensionsArray;
};
