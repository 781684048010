/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { TemplateCondition, TemplateLogic, TemplatePage, TemplateVisibilityLogic } from '@app/types/template.types';

export const applyLogic = (pages: TemplatePage[], logic: TemplateLogic[]): TemplatePage[] => {
  const mergeConditions = (existingConditions: TemplateCondition[], newConditions: TemplateCondition[]): TemplateCondition[] =>
    existingConditions.concat(newConditions);

  const pagesWithLogic = structuredClone(pages);

  logic.forEach((rule) => {
    const targetStatementIds = rule.actions.AND.map((action) => action.catalogStatement?.id);

    pagesWithLogic.forEach((page) => {
      const targetIndexes = page.statements
        .map((statement, i) => (targetStatementIds.includes(statement.id) ? i : -1))
        .filter((index) => index !== -1);

      targetIndexes?.forEach((index) => {
        const existingVisibleIf = page.statements[index].visibleIf || {} as TemplateVisibilityLogic;
        const newVisibleIf = rule.conditions;

        page.statements[index].visibleIf = {
          AND: mergeConditions(existingVisibleIf?.AND || [], newVisibleIf?.AND || []),
          OR: mergeConditions(existingVisibleIf?.OR || [], newVisibleIf?.OR || []),
        };
      });
    });
  });

  return pagesWithLogic;
};
