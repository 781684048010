/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AssessmentStatement, CatalogDimension, IScoreDetailList, IVisibleCondition, ScoreDetail } from '@app/types/catalog.types';
import { ANSWER_TYPES, VISIBLE_CONDITION } from '@lib/common.constants';
import { ScaleService } from '@services/scale.service';

export const getConditionForAssociatedStatement = (allConditions: IVisibleCondition[], targetStatement: AssessmentStatement) =>
  allConditions?.find(
    (condition) =>
      condition.catalogStatement.id === targetStatement.catalogStatementId ||
      condition.catalogStatement?.id === targetStatement.id,
  );

export const checkCondition = (
  method: VISIBLE_CONDITION,
  statement: AssessmentStatement,
  preparedStatements: AssessmentStatement[],
  userId: string,
  voterId: string,
  initialCheck?: boolean,
) => {
  const results = statement.visibleIf[method].map((condition) => {
    const foundStatement = findStatementByCondition(preparedStatements, condition);

    if (!foundStatement?.visible && !initialCheck) {
      return false;
    }

    return checkAnswer(foundStatement, condition, userId, voterId);
  });

  if (method === VISIBLE_CONDITION.OR) {
    return results.some((answer) => answer);
  }
  return results.every((answer) => answer);
};

const findStatementByCondition = (preparedStatements: AssessmentStatement[], condition: IVisibleCondition) =>
  preparedStatements?.find(
    (st) =>
      st?.id === condition.catalogStatement.id ||
      st?.catalogStatementId === condition?.catalogStatement.id,
  );

const checkAnswer = (
  statementToCheck: AssessmentStatement | undefined,
  conditionToCheck: IVisibleCondition,
  userId: string,
  voterId: string,
) => {
  if (!statementToCheck) {
    return false;
  }
  const answer =
    !ScaleService.isRadioGroup(statementToCheck.type) &&
    !ScaleService.isMultiGroup(statementToCheck.type)
      ? conditionToCheck.answer.toUpperCase()
      : conditionToCheck.answer;

  const voterIndex = getVoterIndex(statementToCheck.userScores, userId, voterId);
  const score = ((statementToCheck.userScores || {})[userId] || [])[voterIndex] || {};

  if (ScaleService.isMultiGroup(statementToCheck.type)) {
    if (!score.userChoices) {
      return false;
    }
    return score.userChoices.includes(answer);
  }
  return answer === score.userScore;
};

export const getVoterIndex = (userScores: IScoreDetailList = {}, userId: string, voterId: string) =>
  (userScores[userId] || []).findIndex(
    (voterItem) => voterItem.voterId === voterId,
  );

  export const sortStatement = (statementsArray: AssessmentStatement[]) =>
    statementsArray.sort((statementA, statementB) => {
      if (statementA.order === statementB.order) {
        return statementA.description.localeCompare(statementB.description);
      }
      return statementA.order - statementB.order;
    });

export const resetUserScores = (
  updatedStatement: AssessmentStatement,
  voterId: string,
  userId: string,
  isUserChoicesReset?: boolean
): AssessmentStatement => {
  const initScores = updatedStatement?.userScores;

  if (!initScores || !initScores[userId]) {
    return updatedStatement;
  }

  const userScores = structuredClone(updatedStatement?.userScores[userId]);
  const voterIndex = userScores.findIndex((vot: ScoreDetail) => vot.voterId === voterId);

  return {
    ...updatedStatement,
    userScores: {
      ...updatedStatement.userScores,
      [userId]: setDefaultScore(userScores, updatedStatement.type, voterIndex, isUserChoicesReset),
    },
    defaultScores: {},
  };
};

const setDefaultScore = (userScores: ScoreDetail[], type: ANSWER_TYPES, voterIndex: number, isUserChoicesReset?: boolean) => {
  const isMultiGroup = ScaleService.isMultiGroup(type);
  const isOpenEnded = ScaleService.isOpenEnded(type);

  if (voterIndex > -1) {
    userScores[voterIndex].userScore = null;
    userScores[voterIndex].value = -1;

    if ((isMultiGroup || isOpenEnded) && isUserChoicesReset) {
      userScores[voterIndex].userChoices = [];
    }
  }
  return userScores;
}

export const sortDimensionsByPageAndOrder = (dimensions: CatalogDimension[], statementsList: AssessmentStatement[]) =>
  dimensions.reduce((acc, dimension) => {
    const dimensionStatementsIDs = dimension?.statements.map(st => st.id);
    const currentDimensionStatements = sortStatement(statementsList.filter(st => dimensionStatementsIDs.includes(st.id)));

    return [...acc, ...currentDimensionStatements];
  }, [] as AssessmentStatement[]);
