/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AnalyticsEvent } from '@services/analytics/analytics.types';

export function isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const sortEventProperties =
  () =>
  ({ name, properties }: AnalyticsEvent): AnalyticsEvent => ({
    name,
    properties: Object.fromEntries(
      Object.entries(properties).sort(([key1], [key2]) => key1.localeCompare(key2)),
    ),
  });
