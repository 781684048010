/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette, sourceSansPro } from '@root/src/utils/variables';

export const scaleStyles = {
  root: css`
    width: 95%;
  `,

  scaleWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-width: fit-content;
  `,

  scaleSimpleWrapper: css`
    width: 100%;
  `,

  scaleBlock: (isSimple: boolean) => css`
    width: 100%;
    margin-left: -8px;
    ${isSimple ? 'max-width: 600px;' : ''}
  `,

  description: css`
    white-space: normal;
    word-wrap: break-word;
    font-size: 12px;

    & > * {
      font-size: 12px;
      font-family: ${sourceSansPro};
      text-align: start;
      margin-top: 0;
      margin-bottom: 0;
    }
  `,

  textSection: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 200px;
    margin-left: 10px;
  `,

  disabledStyle: css`
    pointer-events: none;
  `,

  commentButtons: css`
    display: flex;
  `,
};

export const stepLabel = (disabled: boolean, color?: string, size?: number) => css`
  color: ${color || Colors.textPrimary};
  font-size: ${size || 14}px;
  font-family: ${sourceSansPro};
  margin-top: ${color ? '0' : '6px'};
  margin-left: ${color ? '2px' : '0'};
  margin-bottom: 8px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  text-align: start;
`;

export const infoIconStyle = css`
  margin-Left: 8px;
  cursor: pointer;

  > svg {
    fill: ${AdditionalColorPalette.Greyscale[600]} !important;
    height: 15px !important;
    width: 15px !important;
  }
`;
