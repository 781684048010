/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';

const baseURL = Constants.BASE_URL;

export interface AmplitudeAnalyticsConfig {
  amplitudeToken: string;
}

export interface AmplitudeAnalyticsServiceApiInterface {
  getConfig(): Promise<AmplitudeAnalyticsConfig>;
}

export function AmplitudeAnalyticsServiceApiFactory(): AmplitudeAnalyticsServiceApiInterface {
  return {
    getConfig() {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/amplitude`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<AmplitudeAnalyticsConfig>) => response.data)
        .catch((error: AxiosError) => {
          throw error;
        });
    },
  };
}

export const AmplitudeAnalyticsServiceApi = AmplitudeAnalyticsServiceApiFactory();
