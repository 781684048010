/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { MouseEvent, useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { CreatableAutocomplete, Input, ModalDialog, ModalDialogOptions, SimpleEvent, TextArea, Tooltip } from '@perf/ui-components';
import { OptionType } from '@perf/ui-components/dist/types/Dropdown.types';
import { UserAPI } from '@api/services/user-api.resources';
import { inputLabelWithHint } from '@root/src/components/common-styled/input.style';
import {
  modalDefaultHeight,
  modalBody,
  modalInput,
  modalSplitBlock,
  modalInputSmall,
  modalInfo,
  modalText,
  modalGrayWrapper,
} from '@app/components/modal/modal.style';
import { findDimension } from '@app/store/catalog.actions';
import { isTypeHasRating } from '@app/utils/dimensions.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { Indicators } from '@components/common/indicators/indicators.component';
import {
  DEFAULT_WEIGHT,
  APP_ROLES,
  NAME_MAX_LENGTH,
  MESSAGE,
  MAX_WEIGHT_LENGTH,
  CATALOG_WEIGHT_INFO,
  NOTE_MAX_LENGTH,
  DEFAULT_INDICATORS,
  SCALE_TYPE,
} from '@lib/common.constants';
import { DIMENSION_FIELDS } from '@lib/data-fields.constants';
import { BASE_CATALOG_VIEW_TYPES, INHERIT_INDICATORS } from '@lib/event.constants';
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { ScaleService } from '@services/scale.service';
import { Dimension, DimensionTag, IndicatorInfo, Statement } from '@app/types/dimension.types';
import { User } from '@root/src/types/user.types';

interface DimensionModalProps {
  title: string;
  dimension: Dimension;
  isDisabled: boolean;
  showModerators: boolean;
  isModeratorsDisabled: boolean;
  onAccept: (dimension: Dimension) => void;
  user: User;
  isInheritedDisabled: boolean;
  baseView: BASE_CATALOG_VIEW_TYPES;
};

const DimensionModal = ({
  title,
  dimension,
  isDisabled,
  showModerators,
  isModeratorsDisabled,
  onAccept,
  user,
  baseView,
  isInheritedDisabled,
}: DimensionModalProps) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [tagValue, setTagValue] = useState('');
  const [moderators, setModerators] = useState([]);
  const [localDimension, setLocalDimension] = useState(
    JSService.isUndefinedOrNull(dimension.weight)
      ? { ...dimension, weight: DEFAULT_WEIGHT }
      : dimension,
  );

  const isUserGA = AccessDecisionManager.isGlobalAdmin(user);
  const isUserAdmin = AccessDecisionManager.isTenantAdmin(user);
  const isUserModerator = localDimension.moderators?.some((moderator) => moderator.id === user?.id);
  const isUserExpert = AccessDecisionManager.isTenantExpert(user);

  const findUsers = (query: string) => {
    UserAPI.search(query, [APP_ROLES.ADMIN, APP_ROLES.EXPERT, APP_ROLES.GLOBAL_ADMIN])
      .then((response) => {
        setModerators(response);
      })
      .catch(() => {
        setModerators([]);
      });
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleData = (event: SimpleEvent<string>, field: string) => {
    const dimension = { ...localDimension };
    const value =
      (field === 'weight' && event.target.value)
        ? CatalogService.getCorrectedWeight(+event.target.value, dimension[field])
        : event.target.value;

    dimension[field] = value;

    setLocalDimension(dimension);
  };

  const hasInputTag = (tags: DimensionTag[]) => !!tags.find((tag) => tag.tag === tagValue);

  const isErrorWeight = () => !localDimension.weight || localDimension.weight <= 0;

  const isModerators = () => localDimension.moderators?.length > 0;

  const isLevelName = () =>
    localDimension.indicatorInfo
      ? Object.values(localDimension.indicatorInfo).every((indicator) => indicator.name)
      : true;

  const onTagCreate = (event: KeyboardEvent) => {
    if (!tagValue) return;

    const dimension = { ...localDimension };

    if (event.key === 'Enter') {
      if (!dimension.tags) {
        dimension.tags = [];
      }

      if (!hasInputTag(dimension.tags)) {
        dimension.tags.push({
          tag: tagValue,
        } as DimensionTag);
        setTagValue('');
        setLocalDimension(dimension);
      }
      event.preventDefault();
    }
  };

  const updateIndicators = (indicators: IndicatorInfo) => {
    const updatedDimension = structuredClone(localDimension);

    updatedDimension.indicatorInfo = indicators;

    setLocalDimension(updatedDimension);
  };

  const initDimension = (indicatorInfo: IndicatorInfo) => {
    const updatedDimension = structuredClone(localDimension);

    updatedDimension.indicatorInfo = indicatorInfo;
    updatedDimension.inheritLevelsName = initInheritLevelsName;
    updatedDimension.inheritLevelsDesc = initInheritLevelsDesc;

    setLocalDimension(updatedDimension);
  };

  const setDefaultIndicatorInfoFields = (updatedDimension: Dimension) => {
    const targetDimension = structuredClone(updatedDimension);
    const defaultIndicators = DEFAULT_INDICATORS.RATING;

    Object.keys(targetDimension.indicatorInfo).forEach((indicatorId) => {
      if (!targetDimension.inheritLevelsName) {
        targetDimension.indicatorInfo[indicatorId].name = defaultIndicators[indicatorId].name;
      }
      if (!targetDimension.inheritLevelsDesc) {
        targetDimension.indicatorInfo[indicatorId].description = defaultIndicators[indicatorId].description;
      }
    });

    return targetDimension;
  }

  const updateInheritance = (eventName: string, value: boolean) => {
    const updatedDimension = structuredClone(localDimension);

    if (eventName === INHERIT_INDICATORS.LEVEL_NAME) {
      updatedDimension.inheritLevelsName = value;
    } else {
      updatedDimension.inheritLevelsDesc = value;
    }

    setLocalDimension(!value ? setDefaultIndicatorInfoFields(updatedDimension) : updatedDimension);
  };

  const updateIndicatorNamesAndDescriptions = (currentStatement: Statement, updatedDimension: Dimension) => {
    const inInherited = currentStatement.inheritLevelsName || currentStatement.inheritLevelsDesc;
    const isRated =
      ScaleService.isOpenEnded(currentStatement.type) ||
        ScaleService.isMultiGroup(currentStatement.type)
        ? currentStatement.rated
        : true;

    if (
      inInherited &&
      isRated &&
      Object.keys(updatedDimension?.indicatorInfo).length &&
      isTypeHasRating(currentStatement.type)
    ) {
      currentStatement.indicatorInfo = JSService.isEmptyOrNullObject(
        currentStatement?.indicatorInfo,
      )
        ? getUpdatedIndicatorInfo(updatedDimension) as IndicatorInfo
        : currentStatement?.indicatorInfo;

      Object.keys(currentStatement.indicatorInfo).forEach((indicatorId) => {
        if (currentStatement.inheritLevelsName) {
          currentStatement.indicatorInfo[indicatorId].name =
            updatedDimension.indicatorInfo[indicatorId].name;
        }
        if (currentStatement.inheritLevelsDesc) {
          currentStatement.indicatorInfo[indicatorId].description =
            updatedDimension.indicatorInfo[indicatorId].description;
        }
      });
    }

    return currentStatement;
  };

  const updateStatementIndicatorInfo = (updatedDimension: Dimension) => {
    if (
      !updatedDimension?.statements ||
      JSService.isEmptyOrNullObject(updatedDimension?.statements)
    ) {
      return updatedDimension?.statements;
    }

    const updatedStatements = updatedDimension.statements;

    Object.keys(updatedStatements).forEach((statementId) => {
      updatedStatements[statementId] = updateIndicatorNamesAndDescriptions(
        updatedStatements[statementId],
        updatedDimension,
      );
    });

    return updatedStatements;
  };

  const updateDimensionIndicatorInfo = (targetDimension: Dimension, parentDimension: Dimension) => {
    const parentIndicatorInfo = getUpdatedIndicatorInfo(parentDimension);

    targetDimension.indicatorInfo = getUpdatedIndicatorInfo(targetDimension) as IndicatorInfo;

    Object.keys(targetDimension.indicatorInfo).forEach((indicatorId) => {
      const currentIndicator = targetDimension.indicatorInfo[indicatorId];
      const parentIndicatorInfoById = parentIndicatorInfo[indicatorId];

      if (targetDimension.inheritLevelsName) {
        currentIndicator.name = parentIndicatorInfoById.name;
      }
      if (targetDimension.inheritLevelsDesc) {
        currentIndicator.description = parentIndicatorInfoById.description;
      }
    });

    return targetDimension;
  };

  const updateIndicatorInfo = (currentSubDimension: Dimension, targetDimensionIndicatorInfo: IndicatorInfo, indicatorId: string) => {
    const currentIndicator = currentSubDimension.indicatorInfo[indicatorId];
    const topLevelDimensionIndicatorInfo = targetDimensionIndicatorInfo[indicatorId];

    if (currentSubDimension.inheritLevelsName) {
      currentIndicator.name = topLevelDimensionIndicatorInfo.name;
    }
    if (currentSubDimension.inheritLevelsDesc) {
      currentIndicator.description = topLevelDimensionIndicatorInfo.description;
    }

    return currentSubDimension.indicatorInfo[indicatorId];
  };

  const updateInheritedIndicatorInfo = (initialDimension: Dimension, updatedParentDimension?: Dimension) => {
    const parentDimension = updatedParentDimension || findDimension(initialDimension.parentId as string, baseView) as Dimension;
    const targetDimension =
      parentDimension && (initialDimension?.inheritLevelsName || initialDimension?.inheritLevelsDesc)
        ? updateDimensionIndicatorInfo(initialDimension, parentDimension)
        : initialDimension;

    targetDimension.statements = updateStatementIndicatorInfo(targetDimension);

    if (
      !targetDimension?.dimensions ||
      JSService.isEmptyOrNullObject(targetDimension?.dimensions)
    ) {
      return targetDimension;
    }

    const updatedDimensions = targetDimension.dimensions;
    const targetDimensionIndicatorInfo = getUpdatedIndicatorInfo(targetDimension);

    Object.keys(updatedDimensions).forEach((dimensionId) => {
      const currentSubDimension = updatedDimensions[dimensionId];

      currentSubDimension.indicatorInfo = getUpdatedIndicatorInfo(currentSubDimension) as IndicatorInfo;

      Object.keys(currentSubDimension.indicatorInfo).forEach((indicatorId) => {
        currentSubDimension.indicatorInfo[indicatorId] = updateIndicatorInfo(
          currentSubDimension,
          targetDimensionIndicatorInfo as IndicatorInfo,
          indicatorId,
        );
      });

      updatedDimensions[dimensionId] = updateInheritedIndicatorInfo(currentSubDimension, targetDimension);
    });

    return {
      ...targetDimension,
      dimensions: updatedDimensions,
    };
  };

  const getUpdatedIndicatorInfo = (currentDimension: Dimension) => {
    const dimensionIndicatorInfo = structuredClone(currentDimension?.indicatorInfo);
    // @ts-ignore
    delete dimensionIndicatorInfo?.id;

    return JSService.isEmptyOrNullObject(dimensionIndicatorInfo)
      ? structuredClone(DEFAULT_INDICATORS.RATING)
      : dimensionIndicatorInfo;
  };

  const saveDimension = (event: MouseEvent<HTMLElement>) => {
    const updatedLocalDimension = updateInheritedIndicatorInfo(structuredClone(localDimension));
    setLocalDimension(updatedLocalDimension);

    validateData(() => {
      onAccept(updatedLocalDimension);
      handleClose(event);
    });
  };

  const validateData = (callback: () => void) => {
    const validateModerators = showModerators ? !isModerators() : false;

    if (!localDimension.name || isErrorWeight() || !isLevelName() || validateModerators) {
      setShowError(true);
    } else {
      callback();
    }
  };

  const updateModerators = (event: SimpleEvent<OptionType | OptionType[]>) => {
    const moderators = event.target.value as User[];
    const dimension = { ...localDimension };

    dimension.moderators = moderators;

    setLocalDimension(dimension);
  };

  const updateTags = (event: SimpleEvent<OptionType | OptionType[]>) => {
    const tags = event.target.value;
    const dimension = { ...localDimension };

    if (!tags) {
      dimension.tags = [];
    } else {
      dimension.tags = tags as DimensionTag[];
    }

    setLocalDimension(dimension);
  };

  const filterOptions = (option: { data: { name: string, email?: string } }, inputValue: string) => {
    const isName = option.data.name?.toLowerCase().includes(inputValue.toLowerCase());
    const isEmail = option.data.email?.toLowerCase().includes(inputValue.toLowerCase());

    return isName || isEmail;
  };

  const isModeratorsEditable = () =>
    (isUserGA || isUserModerator || isUserAdmin || isUserExpert) &&
    !isModeratorsDisabled &&
    !isDisabled;

  const isDimensionEditDisabled = () => {
    if (JSService.isUndefinedOrNull(isDisabled)) {
      return !(isUserGA || isUserModerator);
    }
    return isDisabled;
  };

  const isFirstLevelDimension = !localDimension.parentId;

  const getParentIndicatorInfo = () => {
    if (dimension?.parentIndicatorInfo) {
      return dimension?.parentIndicatorInfo;
    }
    return findDimension(localDimension.parentId as string, BASE_CATALOG_VIEW_TYPES.CATALOG_VIEW)?.indicatorInfo;
  }

  const getDimensionNameInput = () => {
    const input = (
      <Input
        flexible
        type="text"
        label="Name"
        placeholder="Add Name"
        value={localDimension.name || ''}
        onChange={(event) => handleData(event, DIMENSION_FIELDS.NAME)}
        maxLength={NAME_MAX_LENGTH}
        disabled={isDimensionEditDisabled() || CatalogService.isSystem(localDimension)}
        errors={{ name: MESSAGE.FAILURE.MODAL_NAME_SAVE }}
        errorMessages={{ name: MESSAGE.FAILURE.MODAL_NAME_SAVE }}
        touched={showError && !localDimension.name}
      />
    );

    return CatalogService.isSystem(localDimension) ? (
      <Tooltip content="The name of the system dimension cannot be changed." placement="top">
        <div>{input}</div>
      </Tooltip>
    ) : (
      input
    );
  };

  const isParentCustomIndicator = (field: string) => {
    const parentIndicatorInfo = dimension.parentIndicatorInfo;
    const isParentIndicators = !JSService.isEmptyOrNullObject(parentIndicatorInfo);
    const defaultIndicators = structuredClone(DEFAULT_INDICATORS[SCALE_TYPE.RATING.value]);

    if (isParentIndicators) {
      return Object.keys(parentIndicatorInfo).some(
        (indicatorId) =>
          parentIndicatorInfo[indicatorId][field] &&
          parentIndicatorInfo[indicatorId][field] !== defaultIndicators[indicatorId][field],
      );
    }
    return false;
  };

  const initInheritLevelsDesc = dimension.parentIndicatorInfo
    ? isParentCustomIndicator(DIMENSION_FIELDS.DESCRIPTION)
    : localDimension.inheritLevelsDesc;

  const initInheritLevelsName = dimension.parentIndicatorInfo
    ? isParentCustomIndicator(DIMENSION_FIELDS.NAME)
    : localDimension.inheritLevelsName;

  const body = (
    <div css={modalBody}>
      {localDimension && (
        <>
          <div css={modalInput}>{getDimensionNameInput()}</div>
          {!!showModerators && (
            <div css={modalInput}>
              <CreatableAutocomplete
                label={
                  <div css={inputLabelWithHint}>
                    <span>Moderators</span>
                    <Tooltip
                      content={
                        isFirstLevelDimension
                          ? 'Only moderators are allowed to modify the content of the dimension. Assign required moderators.'
                          : 'Moderators are assigned and inherited from the parent dimension.'
                      }
                      placement="top"
                    >
                      <InfoOutline18 />
                    </Tooltip>
                  </div>
                }
                flexible
                disabled={!isModeratorsEditable()}
                creatable={false}
                placeholder="Add Moderator"
                isMulti
                // @ts-ignore
                loadOptions={findUsers}
                options={moderators}
                onInputChange={findUsers}
                value={localDimension.moderators}
                onChange={updateModerators}
                bindLabel="name"
                bindValue="id"
                filterOption={filterOptions}
                errors={{ moderators: MESSAGE.FAILURE.MODERATORS_REQUIRED }}
                errorMessages={{
                  moderators: MESSAGE.FAILURE.MODERATORS_REQUIRED,
                }}
                touched={showError && !isModerators()}
              />
            </div>
          )}
          <div css={[modalInput, modalSplitBlock]}>
            <Input
              type="text"
              label="Weight"
              value={localDimension.weight.toString() || ''}
              onChange={(event) => handleData(event, 'weight')}
              maxLength={MAX_WEIGHT_LENGTH}
              errors={{ weight: MESSAGE.FAILURE.WEIGHT }}
              errorMessages={{ weight: MESSAGE.FAILURE.WEIGHT }}
              touched={showError && isErrorWeight()}
              css={modalInputSmall}
              disabled={isDimensionEditDisabled()}
            />
            <p css={modalInfo}>{CATALOG_WEIGHT_INFO.DIMENSION}</p>
          </div>
          <div css={modalInput}>
            <p css={modalText}>Description</p>
            {/* @ts-ignore */}
            <TextArea
              height={96}
              maxLength={NOTE_MAX_LENGTH}
              placeholder="Add Description"
              value={localDimension.description || ''}
              onChange={(event) => handleData(event, DIMENSION_FIELDS.DESCRIPTION)}
              disabled={isDimensionEditDisabled()}
            />
          </div>
          <div css={modalInput}>
            <CreatableAutocomplete
              label="Tags"
              flexible
              disableMenu
              placeholder="Add Tags"
              isMulti
              isClearable
              disabled={isDimensionEditDisabled()}
              // @ts-ignore
              inputValue={tagValue}
              value={localDimension.tags}
              onInputChange={setTagValue}
              onKeyDown={onTagCreate}
              onChange={updateTags}
              bindLabel="tag"
              bindValue="tag"
            />
          </div>
          <div css={modalGrayWrapper}>
            <Indicators
              initDimension={initDimension}
              indicators={localDimension.indicatorInfo}
              inheritLevelsDesc={initInheritLevelsDesc}
              inheritLevelsName={initInheritLevelsName}
              isSubDimension={
                !JSService.isEmptyOrNullObject(dimension.parentIndicatorInfo) ||
                !isFirstLevelDimension
              }
              parentIndicatorInfo={getParentIndicatorInfo()}
              onChange={updateIndicators}
              onChangeInheritance={updateInheritance}
              isDisabled={isDimensionEditDisabled()}
              showError={showError}
              isInheritedDisabled={isInheritedDisabled}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        disableSave: isDisabled,
        handleCancel: handleClose as () => void,
        handleConfirm: saveDimension as () => void,
      } as ModalDialogOptions}
      css={modalDefaultHeight}
      hidePadding
    />
  );
};

export default DimensionModal;
