/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { MouseEvent, useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import {
  benchmarkInfo,
  benchmarkInfoTitle,
} from '@app/components/modal/benchmark-modal/benchmark-modal.style';
import { modalInput, modalInputLabel, modalDefaultHeight } from '@app/components/modal/modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import BenchmarkList from '@components/common/benchmark-list/benchmark-list.component';
import { INDUSTRY_BENCHMARK } from '@lib/common.constants';
import { CatalogDimension } from '@app/types/catalog.types';

interface BenchmarkModalProps {
  title: string;
  description: string;
  dimensions: CatalogDimension[];
  isDisabled: boolean;
  onAccept: (dimensions: CatalogDimension[]) => void;
}

const BenchmarkModal = ({ title, description, dimensions = [], isDisabled, onAccept }: BenchmarkModalProps) => {
  const [show, setShow] = useState(true);
  const [localDimensions, setLocalDimensions] = useState(dimensions);

  const saveBenchmarks = (event: MouseEvent<HTMLElement>) => {
    onAccept(localDimensions);
    handleClose(event);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const deepAttributeReplace = (target: CatalogDimension[] | CatalogDimension, dimensionId: string, keyName: string, keyValue: boolean | number) => {
    for (const key in target) {
      if (key === 'catalogDimensionId' && target[key] === dimensionId) {
        target[keyName] = keyValue;
      } else if (Array.isArray(target[key])) {
        target[key].forEach((member: CatalogDimension) =>
          deepAttributeReplace(member, dimensionId, keyName, keyValue),
        );
      } else if (typeof target[key] === 'object') {
        deepAttributeReplace(target[key], dimensionId, keyName, keyValue);
      }
    }
  };

  const activateDimension = (dimensionId: string, isActive: boolean) => {
    const dimensions = JSService.getObjectCopy(localDimensions) as CatalogDimension[];
    deepAttributeReplace(dimensions, dimensionId, 'isActive', !isActive);
    setLocalDimensions(dimensions);
  };

  const changeDimensionScore = (dimensionId: string, expectedScore: number) => {
    const dimensions = JSService.getObjectCopy(localDimensions) as CatalogDimension[];
    deepAttributeReplace(dimensions, dimensionId, 'expectedScore', expectedScore);
    setLocalDimensions(dimensions);
  };

  const body = (
    <div css={modalInput}>
      <p css={modalInputLabel}>{description}</p>
      <section css={benchmarkInfo}>
        <span css={benchmarkInfoTitle}>dimensions</span>
        <span css={benchmarkInfoTitle}>{INDUSTRY_BENCHMARK}</span>
      </section>
      <BenchmarkList
        onActivateDimension={activateDimension}
        dimensions={localDimensions}
        isDisabled={isDisabled}
        onChangeDimensionScore={changeDimensionScore}
      />
    </div>
  );

  return (
    <ModalDialog
      isShow={show}
      maxWidth="sm"
      fullWidth
      container={getRootTarget}
      config={{
        title,
        body,
        handleCancel: handleClose as () => void, // @perf/ui-components bug with types
        handleConfirm: saveBenchmarks as () => void, // @perf/ui-components bug with types
      }}
      css={modalDefaultHeight}
    />
  );
};

export default BenchmarkModal;
