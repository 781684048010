/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ReviewAPI, ReviewVoterAPI } from '@app/api/services/review-api.resources';
import { calculateProgress, modifyDimensionData } from '@components/views/assessment-view/assessment-view.utils';
import { JSService } from '@services/js.service';
import { NotificationService } from '@services/notification.service';
import { ReviewService } from '@services/review.service';
import { ScaleService } from '@services/scale.service';
import { AssessmentStatement, CatalogDimension, IScoreDetailList, IStatementComment, IVoter } from '@app/types/catalog.types';
import { getStatementCount } from '@utils/review-helpers.utils';
import { AnonymsAssessmentActions, useAnonymsAssessmentStore } from '@app/store/use.anonyms-assessment.store';
import { AnonymsAssessmentState, AnonymsAssessmentStateInterface } from '@app/store/store.types';

export const setAnonymsAssessmentState = (
  updatedAssessmentState: Partial<AnonymsAssessmentStateInterface>,
  action: AnonymsAssessmentActions,
  callbackFn?: (state: AnonymsAssessmentStateInterface) => void,
): void => {
  useAnonymsAssessmentStore.setState((state: AnonymsAssessmentState) => ({
    assessmentState: {
      ...state.assessmentState,
      ...updatedAssessmentState,
    },
  }), false, action);

  if (callbackFn) {
    const { assessmentState } = useAnonymsAssessmentStore.getState();
    callbackFn?.(assessmentState);
  }
};

export const createAnonVoter = (reviewId: string, linkId: string) => {
  ReviewVoterAPI.createAnonVoter(reviewId, linkId)
    .then((res) => setAnonymsAssessmentState({ voter: res }, AnonymsAssessmentActions.setVoter))
    .catch(NotificationService.showError);
};

export const saveScores = (
  scores: {
    scoresByStatementId: IScoreDetailList;
},
  comments: IStatementComment[],
  authorJob: string,
  authorName: string,
  voterId: string,
  reviewId: string,
  callback: () => void,
) => {
  ReviewAPI.sendAnonymousReview(
    {
      commentDTOS: comments,
      scoresWrapper: scores,
      voterInfo: {
        jobTitle: authorJob,
        name: authorName,
        voterId,
      },
    },
    reviewId,
  )
    .then(() => {
      if (callback) callback();
    })
    .catch(ReviewService.showError);
};

export const finishAssessment = (reviewId: string, voterId: string) => {
  ReviewVoterAPI.finish(reviewId, voterId).catch(NotificationService.showError);
};

export const setAnonymsProgress = (_ignoreSave?: boolean, updatedStatements?: AssessmentStatement[], dimensionId?: string) => {
  const { review } = useAnonymsAssessmentStore.getState().assessmentState;
  const updatedReview = structuredClone(review);

  if (updatedStatements) {
    updatedReview.dimensions = modifyDimensionData(
      updatedReview.dimensions,
      updatedStatements,
      dimensionId || '',
    );
  }

  updatedReview.dimensions = setSelectedStatementCount(updatedReview.dimensions) as CatalogDimension[];
  setAnonymsAssessmentState({ review: updatedReview }, AnonymsAssessmentActions.setReview);

  if (updatedReview.dimensions) {
    getAnonymsUserProgress();
  }
};

const setSelectedStatementCount = (dimensions: CatalogDimension[]): CatalogDimension[] | undefined => {
  if (!dimensions) {
    return;
  }

  return dimensions.map((dimension) => ({
      ...dimension,
      selectedStatementCount: getSelectedStatementCount(dimension),
      dimensions: setSelectedStatementCount(dimension.dimensions),
    } as CatalogDimension));
};

const getSelectedStatementCount = (dimensionParent: CatalogDimension) => {
  let count = 0;

  if (dimensionParent.dimensions) {
    dimensionParent.dimensions.forEach((dimension) => {
      count += getSelectedStatementCount(dimension);
    });
  }
  if (dimensionParent.statements) {
    const { voter: voterData, user: userData } = useAnonymsAssessmentStore.getState().assessmentState;
    const voter = voterData ?? {} as IVoter;
    dimensionParent.statements.forEach((statement) => {
      const voterInStatementScores =
        statement.userScores && statement.userScores[userData.id] && voter
          ? statement.userScores[userData.id].find((user) => user.voterId === voter?.id)
          : {} as IScoreDetailList;

      const isNotMultiGroup = !ScaleService.isMultiGroup(statement.type);
      const hasUserScore = voterInStatementScores && voterInStatementScores.userScore;
      const hasUserChoices = voterInStatementScores && voterInStatementScores.userChoices?.length;

      if ((isNotMultiGroup && hasUserScore) || hasUserChoices) {
        count++;
      }
    });
  }
  return count;
};

export const getAnonymsUserProgress = (callback?: () => void) => {
  const { review: reviewData } = useAnonymsAssessmentStore.getState().assessmentState;
  if (!JSService.getObjectLength(reviewData.dimensions)) {
    return;
  }

  let statementsCount = 0;
  let selectedStatementCount = 0;

  reviewData.dimensions.forEach((dimension) => {
    statementsCount += getStatementCount(dimension);
    selectedStatementCount += getSelectedStatementCount(dimension);
  });

  const userProgress = calculateProgress(selectedStatementCount, statementsCount);
  setAnonymsAssessmentState({ userProgress }, AnonymsAssessmentActions.userProgress);
  callback?.();
};

export const setStatementCount = (dimensions: CatalogDimension[]) => dimensions.map((dimension) => {
    dimension.statementLength = +getStatementCount(dimension);

    if (dimension.dimensions) {
      dimension.dimensions = setStatementCount(dimension.dimensions);
    }

    return dimension;
  });
