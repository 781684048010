/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Observable, OperatorFunction, Subscriber } from 'rxjs';
import { isEqual } from 'lodash';
import { AnalyticsEvent } from '@services/analytics/analytics.types';

interface EventDetails {
  properties: Record<string, unknown>;
  time: number;
}

class DuplicateEventFilter<T extends AnalyticsEvent> {
  private lastEmittedEvents = new Map<string, EventDetails>();

  private cleanupIntervalId: ReturnType<typeof setInterval> | null = null;

  constructor(private period: number, private subscriber: Subscriber<T>) {}

  public handleNext(event: T): void {
    const { name, properties } = event;
    const currentTime = Date.now();
    const lastEmitted = this.lastEmittedEvents.get(name);

    if (this.shouldEmitEvent(event, lastEmitted, currentTime)) {
      this.subscriber.next(event);
      this.lastEmittedEvents.set(name, { properties, time: currentTime });
      this.manageCleanupInterval();
    }
  }

  public handleError(err: Error): void {
    this.clearCleanupInterval();
    this.subscriber.error(err);
  }

  public handleComplete(): void {
    this.clearCleanupInterval();
    this.subscriber.complete();
  }

  private shouldEmitEvent(
    event: T,
    lastEmitted: EventDetails | undefined,
    currentTime: number,
  ): boolean {
    return (
      !lastEmitted ||
      currentTime - lastEmitted.time > this.period ||
      !isEqual(lastEmitted.properties, event.properties)
    );
  }

  private manageCleanupInterval(): void {
    if (this.lastEmittedEvents.size > 0 && !this.cleanupIntervalId) {
      this.cleanupIntervalId = setInterval(() => this.cleanup(), this.period);
    } else if (this.lastEmittedEvents.size === 0 && this.cleanupIntervalId) {
      this.clearCleanupInterval();
    }
  }

  private cleanup(): void {
    const currentTime = Date.now();
    this.lastEmittedEvents.forEach((value: EventDetails, key: string) => {
      if (currentTime - value.time > this.period) {
        this.lastEmittedEvents.delete(key);
      }
    });
    this.manageCleanupInterval();
  }

  private clearCleanupInterval(): void {
    if (this.cleanupIntervalId) {
      clearInterval(this.cleanupIntervalId);
      this.cleanupIntervalId = null;
    }
  }
}

export function filterDuplicateEvents<T extends AnalyticsEvent>(
  period: number,
): OperatorFunction<T, T> {
  return (source: Observable<T>): Observable<T> =>
    new Observable<T>((subscriber) => {
      const filter = new DuplicateEventFilter<T>(period, subscriber);

      return source.subscribe({
        next: filter.handleNext.bind(filter),
        error: filter.handleError.bind(filter),
        complete: filter.handleComplete.bind(filter),
      });
    });
}
