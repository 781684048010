/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReactElement } from 'react';
import { CommunicationChatAlt24 } from '@core/icons/CommunicationChatAlt24';
import { Tooltip } from '@perf/ui-components';
import {
  documentColumnStyles,
  documentColumnTextContentStyles,
  documentItem,
  documentItemBase,
  documentRow,
  documentsListStyles,
  firstDocumentItem,
  lastDocumentItem,
  tooltipMaxWidth,
} from '@components/common/statements-survey/documents-list/documents-list.style';
import ToggleMenu from '@components/element/toggle-menu/toggle-menu.component';
import { MainColorPalette } from '@utils/variables';
import { StatementDocument } from '@root/src/types/dimension.types';
import { Colors } from '@root/src/lib/colors.constants';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';

const COLUMN_WIDTH = {
  extension: '80px',
  size: '80px',
  comment: '84px',
  options: '46px',
};

interface DocumentsListProps {
  documents: StatementDocument[],
  options: (doc: StatementDocument) => {
    label: string;
    icon: ReactElement;
    action: () => void;
  }[],
  onRemove: (id: string) => void;
  isFinished?: boolean;
};

const DocumentsList = ({ documents, options, onRemove, isFinished }: DocumentsListProps) => (
  <div css={documentsListStyles}>
    {documents
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .map((doc, index) => (
        <div css={documentRow} key={doc.id}>
          <div css={[
            documentItemBase,
            index === 0 ? firstDocumentItem : documentItem,
            index === documents.length - 1 ? lastDocumentItem : documentItem,
          ]}>
            <div
              css={[
                documentColumnStyles,
                {
                  width: `calc(100% - ${COLUMN_WIDTH.extension} -
                    ${COLUMN_WIDTH.size} - ${COLUMN_WIDTH.comment} - ${COLUMN_WIDTH.options})`,
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Tooltip content={<div css={tooltipMaxWidth}>{doc.name}</div>} placement="top">
                <span css={documentColumnTextContentStyles}>{doc.name}</span>
              </Tooltip>
            </div>
            <div css={[documentColumnStyles, { width: COLUMN_WIDTH.extension }]}>
              <span css={documentColumnTextContentStyles}>{doc.extension}</span>
            </div>
            <div css={[documentColumnStyles, { width: COLUMN_WIDTH.size }]}>
              <span css={documentColumnTextContentStyles}>{doc.size}</span>
            </div>
            <div css={[documentColumnStyles, { width: COLUMN_WIDTH.comment }]}>
              <Tooltip
                content={<div css={tooltipMaxWidth}>{doc.comment || 'No comment added'}</div>}
                placement="top"
              >
                <CommunicationChatAlt24
                  color={
                    doc.comment ? MainColorPalette.Grey[500] : Colors.disabled
                  }
                  size='medium'
                />
              </Tooltip>
            </div>
            <div
              css={[
                documentColumnStyles,
                { width: COLUMN_WIDTH.options, justifyContent: 'flex-end' },
              ]}
            >
              {!!options(doc).length && (
                <ToggleMenu color={Colors.disabled} options={options(doc)} />
              )}
            </div>
          </div>
          <SurveyClearButton
            message='Delete document'
            onReset={() => onRemove(doc.id)}
            isDisabled={isFinished}
          />
        </div>
      ))}
  </div>
);

export default DocumentsList;
