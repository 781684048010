/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useState, useMemo } from 'react';
import { IconButton } from '@perf/ui-components';
import { ChevronDown18 } from '@core/icons/ChevronDown18';
import { ChevronRight18 } from '@core/icons/ChevronRight18';
import CommentScore from '@components/common/comment-score/comment-score.component';
import { commentScoreSectionStyles } from '@components/common/comment-score-section/comment-score-section.style';
import { AssessmentStatement, IScoreDetailList, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { AssessmentState } from '@root/src/store/store.types';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { JSService } from '@root/src/services/js.service';
import { User } from '@root/src/types/user.types';
import { AccessDecisionManager } from '@root/src/services/access-decision-manager.service';
import { ReviewService } from '@root/src/services/review.service';
import { APP_REVIEW_ROLES } from '@root/src/lib/common.constants';
import { filterUsersByRole } from '@root/src/utils/user.utils';


interface CommentScoreSectionProps {
  statement: AssessmentStatement;
  user: User;
}

const CommentScoreSection = ({ statement, user }: CommentScoreSectionProps) => {
  const [isAnswersOpen, setIsAnswersOpen] = useState(false);
  const {
    voters,
    users,
    activeTabId,
  } = useAssessmentStore((state) => ({
    voters: (state as AssessmentState).assessmentState.allVoters,
    users: (state as AssessmentState).assessmentState.review.users,
    activeTabId: (state as AssessmentState).assessmentState.activeTabId,
  }));
  const experts = users?.length ? filterUsersByRole(users, APP_REVIEW_ROLES.REVIEW_EXPERT) : [];
  const clients = users?.length ? filterUsersByRole(users, APP_REVIEW_ROLES.REVIEW_CLIENT) : [];

  const selectedVoterTabId = voters?.find((voter) => voter.id === activeTabId)?.id;
  const isNonVoted = (scoreItem: ScoreDetail) => (!scoreItem.value || scoreItem.value === -1) && (scoreItem.userChoices?.length === 0 || !scoreItem.userChoices) && !scoreItem.userScore;

  const filterUniqueVoters = (scoresArray: ScoreDetail[]) => {
    const map = new Map<string, ScoreDetail>();

    scoresArray?.forEach((obj) => {
      if (!map.has(obj.voterId) || (map.has(obj.voterId) && obj.value !== -1)) {
        map.set(obj.voterId, obj);
      }
    });

    return Array.from(map.values());
  };

  const filterScores = (userScores: IScoreDetailList) => {
    const filteredScoresData = {} as IScoreDetailList;

    Object.entries(userScores).forEach(([scoreId, score]) => {
      const filteredScore = score?.filter((scoreItem) => {
        if (scoreItem.voterId !== selectedVoterTabId && scoreItem.commentId) {
          return true;
        }
        return scoreItem.voterId !== selectedVoterTabId && !isNonVoted(scoreItem);
      });

      filteredScoresData[scoreId] = filterUniqueVoters(filteredScore);
    });

    return filteredScoresData;
  };

  const convertCommentsToUserScores = (userScores: IScoreDetailList) => {
    const updatedUserScores = structuredClone(userScores);

    statement?.comments?.forEach((comment) => {
      const currentUser = [...experts, ...clients]?.find((campaignUser) => comment.reviewer?.id === campaignUser.id);
      const voterId = comment.voterId as string;
      const userId = currentUser?.id as string;

      if (userId) {
        const newScore = {
          userScore: null,
          userChoices: [],
          voterId,
          expertId: '',
          value: -1,
          updatedAt: '',
          commentId: comment.id,
        };
        if (!updatedUserScores[userId]) {
          updatedUserScores[userId] = [newScore];
        } else {
          const isScoreExist = updatedUserScores[userId].some((score) => score.voterId === voterId && score.commentId);

          if (!isScoreExist) {
            updatedUserScores[userId].push(newScore);
          }

        }
      }
    });

    return updatedUserScores;
  }

  const userScoresWithComments = useMemo(() => convertCommentsToUserScores(statement.userScores || []), [statement.comments]);
  const filteredScores = useMemo(() => filterScores(userScoresWithComments), [userScoresWithComments, selectedVoterTabId]);

  const calculateScoreCount = () => Object.values(filteredScores).reduce((count, scores) => count + scores.length, 0);

  const scoreCount = useMemo(() => calculateScoreCount(), [statement, clients, experts]);

  const showScores = (scores: IScoreDetailList, users: User[]) => {
    if (!JSService.getObjectLength(scores)) {
      return false;
    }
    return Object.keys(scores).filter((userId) => scores[userId]?.length && users?.find((userItem) => userItem.id === userId)).length > 0;
  };

  const isAssessorScoresVisible = () => {
    const isAdmin = AccessDecisionManager.isGlobalAdmin(user)
    const isExpert = ReviewService.hasUser(
      ReviewService.getUsersByRole(
        experts,
        APP_REVIEW_ROLES.REVIEW_EXPERT,
      ),
      user?.id,
    );

    return isAdmin || isExpert;
  }

  return (
    <>
      {!!scoreCount && isAssessorScoresVisible() &&
        <div css={commentScoreSectionStyles.answersBlock}>
          <div css={commentScoreSectionStyles.answersExpandButton}>
            <IconButton
              onClick={() => setIsAnswersOpen(!isAnswersOpen)}
              size="medium"
            >
              {isAnswersOpen ? <ChevronDown18 /> : <ChevronRight18 />}
            </IconButton>
            <div css={commentScoreSectionStyles.answersExpandText}>
              Other answers ({scoreCount}):
            </div>
          </div>
          {isAnswersOpen && showScores(filteredScores, experts) &&
            <CommentScore
              headerMessage="Assessors score"
              userScores={filteredScores}
              users={experts}
              voters={voters as IVoter[]}
              comments={statement.comments}
              voterDocuments={statement.voterDocuments}
              indicatorInfo={statement.indicatorInfo}
              indicatorChoices={statement.indicatorChoices}
            />
          }
          {isAnswersOpen && showScores(filteredScores, clients) &&
            <CommentScore
              headerMessage="Respondent score"
              userScores={filteredScores}
              users={clients}
              voters={voters as IVoter[]}
              comments={statement.comments}
              voterDocuments={statement.voterDocuments}
              indicatorInfo={statement.indicatorInfo}
              indicatorChoices={statement.indicatorChoices}
            />
          }
        </div>
      }
    </>
  )
}

export default CommentScoreSection;
