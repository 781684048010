/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PropsWithChildren, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureToggle } from '@app/types/feature-toggle.types';
import { useFeatureToggleIsOn } from '@components/common/feature-toggle/use-feature-toggle-is-on';
import { useTrackPage } from '@services/analytics/navigation/analytics-navigation.hook';
import { useAnalytics } from '@services/analytics/analytics.hook';

const AnalyticsServiceProvider = ({ children }: PropsWithChildren) => {
  const analytics = useAnalytics();
  const history = useHistory();
  const trackPage = useTrackPage();

  useEffect(
    () => analytics.navigation.listen(history, trackPage),
    [analytics.navigation, history, trackPage],
  );

  return children;
};

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const isAmplitudeEnabled = useFeatureToggleIsOn(FeatureToggle.AMPLITUDE);

  return isAmplitudeEnabled ? (
    <AnalyticsServiceProvider>{children}</AnalyticsServiceProvider>
  ) : (
    children
  );
};
