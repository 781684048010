/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Button, ButtonType, IconButton, Tooltip } from '@perf/ui-components';
import { ButtonVariant } from '@perf/ui-components/dist/Button/Button.types';
import {
  dummyResult,
  resultPaper,
  withAdditionalPadding,
  dummyResultAsset,
  dummyResultText,
  dummyResultDescription,
  dummyResultButtonWrapper,
  buttonsSection,
} from '@app/components/element/dummy-result/dummy-result.style';
import DASHBOARD_CARDS_SVG from '@app/img/svg/dashboard_cards.svg';
import EMPTY_SUMMARY from '@app/img/svg/empty_summary.svg';
import EMPTY_SUMMARY_ACTIVE from '@app/img/svg/empty_summary_active.svg';
import FULL_SUMMARY from '@app/img/svg/full_summary.svg';
import FULL_SUMMARY_ACTIVE from '@app/img/svg/full_summary_active.svg';
import { JSService } from '@app/services/js.service';
import { primaryHighlight } from '@utils/variables';

interface DummyResultProps {
  src: string;
  message: string;
  description?: string;
  buttons?: {
    name: string,
    type: ButtonType,
    variant?: ButtonVariant,
    onClick: (() => void) | undefined,
  }[];
  paperStyle?: boolean;
  big?: boolean;
  onShowSummaryClick?: () => void;
  summaryButtonTooltip?: string;
  summaryExist?: boolean;
  summaryShown?: boolean;
}

const DummyResult = ({
  src,
  message,
  description,
  buttons,
  paperStyle,
  big,
  onShowSummaryClick,
  summaryButtonTooltip,
  summaryExist,
  summaryShown,
}: DummyResultProps) => {
  const getFullSummaryIcon = () => (summaryShown ? FULL_SUMMARY_ACTIVE : FULL_SUMMARY);

  const getEmptySummaryIcon = () => (summaryShown ? EMPTY_SUMMARY_ACTIVE : EMPTY_SUMMARY);

  const getIconSrc = () => (summaryExist ? getFullSummaryIcon() : getEmptySummaryIcon());

  return (
    <>
      {JSService.isFunction(onShowSummaryClick) && (
        <div css={dummyResultButtonWrapper}>
          <Tooltip
            css={css`
              white-space: nowrap;
            `}
            content={summaryButtonTooltip}
            placement="top"
          >
            <div>
              <IconButton
                onClick={(event) => {
                  (event.currentTarget as any).blur();
                  onShowSummaryClick?.();
                }}
                style={{
                  width: '30px',
                  height: '30px',
                  boxShadow: 'none',
                  ...(summaryShown && {
                    borderColor: primaryHighlight,
                    color: primaryHighlight,
                  }),
                }}
                variant="cancel"
              >
                <img src={getIconSrc() as any} alt="summary-show-click" />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )}
      <section css={[dummyResult, paperStyle && resultPaper, big && withAdditionalPadding]}>
        <img src={(src || DASHBOARD_CARDS_SVG) as any} alt="dummy-result" css={dummyResultAsset} />
        <span css={dummyResultText}>{message}</span>
        {description && <span css={dummyResultDescription}>{description}</span>}
        {!!buttons?.length && (
          <div css={buttonsSection}>
            {buttons.map(({ name, type, variant, onClick }) => (
              <Button
                key={name}
                onClick={onClick}
                type={type}
                variant={variant || 'contained'}
              >
                {name}
              </Button>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default DummyResult;
