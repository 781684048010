/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { StructureState } from '@app/store/store.types';
import { StructureService } from '@services/structure.service';
import { IProduct } from '@app/types/tenant.types';
import { ITenantProduct } from '@app/types/product.types';

const STORE_KEYS = {
  useStructureStore: 'useStructureStore',
};

export enum StructureStoreActions {
  setDefaultUnitId = 'setDefaultUnitId',
  setShowStructure = 'setShowStructure',
  loadUnit = 'loadUnit',
  loadUnitStructure = 'loadUnitStructure',
  setSideStructureTree = 'setSideStructureTree',
};

export const initialStructureState: StructureState = {
  sideStructureTree: {},
  parent: {} as IProduct,
  query: '',
  showStructure: StructureService.showStructure(),
  defaultUnitId: null,
  unit: {} as ITenantProduct,
  loadingIds: [],
  searchQuery: '',
};

export const useStructureStore = create(
  devtools(
    () => initialStructureState,
    { name: STORE_KEYS.useStructureStore },
  ),
);
