/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const DeleteLightIcon = (props: SvgIconProps) => (
  <div style={{ width: props.width, height: props.height }}>
      <SvgIcon {...props} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.63745 2.42383V2.92383H2.13745V3.92383H2.63745V10.4238C2.63745 10.689 2.74281 10.9434 2.93034 11.1309C3.11788 11.3185 3.37223 11.4238 3.63745 11.4238H8.63745C8.90267 11.4238 9.15702 11.3185 9.34456 11.1309C9.53209 10.9434 9.63745 10.689 9.63745 10.4238V3.92383H10.1375V2.92383H7.63745V2.42383H4.63745ZM3.63745 3.92383H8.63745V10.4238H3.63745V3.92383ZM4.63745 4.92383V9.42383H5.63745V4.92383H4.63745ZM6.63745 4.92383V9.42383H7.63745V4.92383H6.63745Z" fill={props.color || '#6C6F80'} />
      </SvgIcon>
  </div>
);
