/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { UserRepositoryAPI } from '@api/services/user-api.resources';
import { LOCAL_STORAGE_KEYS, TENANT_TYPES } from '@lib/common.constants';
import * as Cookies from '@lib/cookies';
import { LocationService } from '@services/location.service';
import { initialGlobalState, useGlobalStore } from '@app/store/use.global.store';

export const handleLogout = () => {
  UserRepositoryAPI.setCurrentUser(null as any);
  const tenantId = localStorage.getItem(TENANT_TYPES.SELECTED_TENANT);
  localStorage.removeItem(TENANT_TYPES.SELECTED_TENANT);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.OAUTH_2_ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARE_REVIEW_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PAGE_COUNT);
  localStorage.removeItem(TENANT_TYPES.CATALOG_TENANT);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.UNIT_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_SSO_TOKEN_SET);
  Cookies.tenant.remove();

  sessionStorage.redirectUrl = LocationService.getCurrentPathName();
  sessionStorage.redirectTenantId = tenantId;

  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.PRISM_CATALOG_GROUPING);
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.REVIEW_IDS);
  useGlobalStore.setState(initialGlobalState);

  LocationService.reloadWindow();
};
